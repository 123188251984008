import React from 'react';

const UNITA = {
  EUR: 'EUR',
  USD: 'USD',
  CHF: 'CHF',
  BGP: 'GBP',
};

function ValutaOptions() {
  return Object.entries(UNITA).map(([key, value], index) => (
    <option key={index} value={value}>
      {key}
    </option>
  ));
}

export default ValutaOptions;

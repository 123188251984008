import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
  name: 'ui',
  initialState: {
    isLoading: false,
    appLoaded: false,
    alert: {
      message: '',
      type: 'success',
    },
  },
  reducers: {
    setAppLoaded: (state) => {
      state.appLoaded = true;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setAlert: (state, { payload }) => {
      state.alert.message = payload.message;
      state.alert.type = payload.type;
    },
    removeAlert: (state) => {
      state.alert.message = '';
      state.alert.type = 'success';
    },
  },
});

export const { setAppLoaded, startLoading, stopLoading, setAlert, removeAlert } = actions;
export default reducer;

// Selectors
export const selectLoadingStatus = ({ ui }) => ui.isLoading;
export const selectAppLoadedStatus = ({ ui }) => ui.appLoaded;
export const selectAlertState = ({ ui }) => ui.alert;

import React from 'react';
import styled from 'styled-components';
import NavBar from './NavBar';

const AppContainer = styled.div`
  height: 100vh;
  margin-left: 100px;
  background-color: #f6f9fd;
`;

function Layout({ children }) {
  return (
    <React.Fragment>
      <NavBar />
      <AppContainer>{children}</AppContainer>
    </React.Fragment>
  );
}

export default Layout;

import styled from 'styled-components';
import { Edit } from '@styled-icons/boxicons-regular/';
import { Delete } from '@styled-icons/material/';

// Page container
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  height: calc(100% - 66px);
  overflow-y: scroll;
  padding: 1rem;
`;

// ---------------------------------------------------------------------
// FORMS

export const Form = styled.form`
  padding: 1rem;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  & h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & h2:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const FormGroupTitle = styled.h2`
  font-size: 2rem;
  color: #95bc00;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

// ---------------------------------------------------------------------
// LISTS

// Filtri

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const FiltersText = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 2px;

  color: #95bc00;
  margin-bottom: 1rem;
`;

export const FiltersInputContainer = styled.div`
  display: flex;
  grid-gap: 1rem;
`;

export const FiltersResetContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 10px;
`;

export const FiltersResetButton = styled.button`
  background: none;
  border: none;

  color: #fff;
  background-color: #95bc00;
  padding: 0.4rem 0.8rem;
  text-transform: uppercase;

  cursor: pointer;
`;

// List

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: #95bc00;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  padding: 1rem 0;
`;

export const ListHeaderText = styled.p`
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  flex: ${({ col = 1 }) => col};
`;

export const ListBodyContainer = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;

  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  overflow-y: ${({ noScroll }) => (noScroll ? 'none' : 'scroll')};
`;

export const ListBodyItem = styled.li`
  padding: 1rem 0;
  display: flex;

  justify-items: center;
  align-items: center;
  background-color: #fff;

  cursor: pointer;

  span {
    font-size: 1.1rem;
  }

  &:hover {
    background-color: #eee;

    span {
    }
  }
`;

export const ListBodyItemText = styled.span`
  text-align: center;
  font-size: 1rem;
  color: #1c1c1c;
  flex: ${({ col = 1 }) => col};
`;

// ---------------------------------------------------------------------
// Add Specilita e Referenti

export const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const AddHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  p {
    font-size: 1.2rem;
  }
`;

export const StyledEditIcon = styled(Edit)`
  cursor: pointer;

  color: #2d8326;
  width: 30px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledDeleteIcon = styled(Delete)`
  cursor: pointer;

  color: #a72608;
  width: 30px;

  &:hover {
    transform: scale(1.1);
  }
`;

// Utilities

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from 'styled-components';

const Button = styled.button`
  display: block;
  color: #fff;
  font-size: 1.4rem;
  padding: 0.6rem 1.8rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const ConfirmButton = styled(Button)`
  background-color: #95bc00;
  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled(Button)`
  background-color: red;
`;

export const SaveButton = styled(Button)`
  background-color: #95bc00;
`;

export const DeleteButton = styled(Button)`
  background-color: red;
`;

export const DisabledButton = styled(Button)`
  background-color: gray;
`;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from 'use-query-params';

import { fetchOrdersList, selectOrdersList, selectOrdersTotal } from '../../../store/orders';

import { PageContainer } from '../../styles';
import { DocumentText } from '@styled-icons/heroicons-solid';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Filters from './Filters';
import List from './List';
import Pagination from '../../../components/Pagination';

function CollaboratorsList() {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrdersList);
  const total = useSelector(selectOrdersTotal);

  const [query, setQuery] = useQueryParams({});

  React.useEffect(() => {
    dispatch(fetchOrdersList(query));
  }, [query]);

  function resetFilters() {
    setQuery({ offset: 0 });
  }

  return (
    <Layout>
      <Header
        text="ORDINI"
        TextIcon={DocumentText}
        buttonTooltipText="Aggiungi un nuovo ordine"
        buttonRedirectUri="/order"
      />
      <PageContainer>
        <Filters query={query} setQuery={setQuery} resetFilters={resetFilters} />
        <List data={orders} />
        <Pagination
          total={total}
          limit={query.limit}
          offset={query.offset}
          onPageChange={(offset) => setQuery({ offset })}
        />
      </PageContainer>
    </Layout>
  );
}

export default CollaboratorsList;

import React from 'react';
import { LogoContainer } from './styles';

function Logo() {
  return (
    <LogoContainer>
      <img src="/logo.png" alt="Logo Translation Agency" />
    </LogoContainer>
  );
}

export default Logo;

import React from 'react';
import { useFormikContext } from 'formik';

import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';

import { formatPrice } from '../../../../utils/currency';

import {
  FormGroupTitle,
  ListContainer,
  ListHeader,
  ListHeaderText,
  ListBodyContainer,
  ListBodyItem,
  ListBodyItemText,
  AddContainer,
  NoDataContainer,
  StyledDeleteIcon,
  StyledEditIcon,
} from '../../../styles';

import { AddButton } from '../../../../components/styles';
import ModalConfirm from '../../../../components/ModalConfirm';
import ServizioAddModal from './ServizioAddModal';
import ServizioEditModal from './ServizioEditModal';

function Servizi() {
  const { values, setValues } = useFormikContext();

  const { Servizi: ServiziValues = [] } = values;

  const onServizioDelete = (servizioIndex) => {
    setValues({
      ...values,
      Servizi: ServiziValues.filter((_, index) => index !== servizioIndex),
    });
  };

  return (
    <React.Fragment>
      <FormGroupTitle>
        Servizi
        <ServizioAddModal>
          <AddButton data-tip="Aggiungi un servizio" style={{ color: ' #95bc00' }} />
        </ServizioAddModal>
      </FormGroupTitle>
      <AddContainer>
        <ListHeader>
          <ListHeaderText>Data Inizio</ListHeaderText>
          <ListHeaderText>Data Prevista</ListHeaderText>
          <ListHeaderText>Lingua Source</ListHeaderText>
          <ListHeaderText>Lingua Target</ListHeaderText>
          <ListHeaderText>Quantità</ListHeaderText>
          <ListHeaderText>Prezzo</ListHeaderText>
          <ListHeaderText></ListHeaderText>
        </ListHeader>

        <ListContainer>
          {ServiziValues.length > 0 ? (
            <ListBodyContainer noScroll>
              {ServiziValues.map((servizio, index) => {
                const {
                  DataInizio,
                  DataPrevista,
                  LinguaSource,
                  LinguaTarget,
                  Quantita = 0,
                  Prezzo = 0,
                } = servizio;

                return (
                  <ListBodyItem key={index}>
                    <ListBodyItemText>
                      {format(new Date(DataInizio), 'dd/MM/yyyy')}
                    </ListBodyItemText>
                    <ListBodyItemText>
                      {format(new Date(DataPrevista), 'dd/MM/yyyy')}
                    </ListBodyItemText>
                    <ListBodyItemText>{LinguaSource}</ListBodyItemText>
                    <ListBodyItemText>{LinguaTarget}</ListBodyItemText>
                    <ListBodyItemText>{Quantita}</ListBodyItemText>
                    <ListBodyItemText>{formatPrice(Prezzo)}</ListBodyItemText>
                    <ListBodyItemText>
                      <ServizioEditModal servizioIndex={index}>
                        <StyledEditIcon />
                      </ServizioEditModal>
                      <ModalConfirm
                        body="Sei sicuro di voler eliminare la specialità?"
                        onConfirm={() => onServizioDelete(index)}>
                        <StyledDeleteIcon />
                      </ModalConfirm>
                    </ListBodyItemText>
                  </ListBodyItem>
                );
              })}
            </ListBodyContainer>
          ) : (
            <NoDataContainer>
              <p>Nessun servizio</p>
            </NoDataContainer>
          )}
        </ListContainer>

        <ReactTooltip />
      </AddContainer>
    </React.Fragment>
  );
}

export default Servizi;

import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';

import { selectAlertState, removeAlert } from '../store/ui';

const AlertVisualizer = ({ children }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const alertState = useSelector(selectAlertState);

  if (alertState.message) {
    const { message, type = 'info' } = alertState;

    alert[type](message, {
      onClose: () => dispatch(removeAlert()),
    });
  }

  return children;
};

export default AlertVisualizer;

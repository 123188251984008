import React, { useEffect, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMe, selectAuthState } from '../store/auth';
import { selectAppLoadedStatus, setAppLoaded } from '../store/ui';

import AppLoading from '../components/AppLoading';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const { token } = useSelector(selectAuthState);
  const appLoaded = useSelector(selectAppLoadedStatus);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAppLoaded());
    }, 1000);
  }, []);

  useEffect(() => {
    if (token !== null) {
      dispatch(fetchMe());

      localStorage.setItem('token', token);
    }
  }, [token]);

  if (!appLoaded) return <AppLoading />;

  return <AuthContext.Provider>{children}</AuthContext.Provider>;
}

export default AuthProvider;

import { createSlice } from '@reduxjs/toolkit';
import { fetchData } from './api';

const INITIAL_STATE = {
  list: [],
  total: 0,
};

const { actions, reducer } = createSlice({
  name: 'invoices',
  initialState: INITIAL_STATE,
  reducers: {
    fetchedInvoicesList: (state, { payload }) => {
      state.list = payload.lista_documenti;
      state.total = payload.numero_risultati;
    },
  },
});

export const { fetchedInvoicesList } = actions;

export default reducer;

// Actions
export const fetchInvoicesList = (params) => {
  return fetchData({
    url: '/invoices',
    params,
    onSuccess: actions.fetchedInvoicesList.type,
  });
};

// Selectors
export const selectInvoicesList = ({ invoices }) => {
  return invoices.list;
};

export const selectInvoicesTotal = ({ invoices }) => {
  return invoices.total;
};

import { createSlice } from '@reduxjs/toolkit';
import { fetchData } from './api';

const INITIAL_STATE = {
  isAuthenticated: false,
  token: localStorage.getItem('token'),
  user: {
    FirstName: '',
    LastName: '',
    Email: '',
  },
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    loggedUser: (state, { payload }) => {
      state.token = payload.token;
    },
    fetchedMe: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = {};

      localStorage.removeItem('token');
    },
  },
});

export const { loggedUser, fetchedMe, logout } = actions;

export default reducer;

// Actions
export const loginUser = (data) => {
  return fetchData({
    url: '/login',
    method: 'POST',
    data,
    onSuccess: actions.loggedUser.type,
  });
};

export const fetchMe = () => {
  return fetchData({
    url: `/me`,
    onSuccess: actions.fetchedMe.type,
  });
};

// Selectors
export const selectAuthState = ({ auth }) => {
  return auth;
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { MATERIA } from '../../../options/MateriaCollaboratoreOptions';

import { ListBodyItem, ListBodyItemText } from '../../styles';

function ListItem({ item = {} }) {
  const history = useHistory();

  const { _id, Generale = {} } = item;

  const goToDetailPage = () => {
    history.push(`/order/${_id}`);
  };

  const { Cliente, Referente, Codice, Data, Descrizione, Responsabile, Materia } = Generale;
  const { RagioneSociale } = Cliente?.Anagrafica;

  return (
    <ListBodyItem onClick={goToDetailPage}>
      <ListBodyItemText>{RagioneSociale}</ListBodyItemText>
      <ListBodyItemText>{Referente}</ListBodyItemText>
      <ListBodyItemText>{Codice}</ListBodyItemText>
      <ListBodyItemText>{Data}</ListBodyItemText>
      <ListBodyItemText>{Descrizione}</ListBodyItemText>
      <ListBodyItemText>{Responsabile}</ListBodyItemText>
      <ListBodyItemText>{MATERIA[Materia]}</ListBodyItemText>
    </ListBodyItem>
  );
}

export default ListItem;

import React from 'react';

import { useFormikContext } from 'formik';

import { FormGroupTitle, FormRow } from '../../styles';

import Select from '../../../components/Select';
import TextArea from '../../../components/TextArea';
import { ConfirmButton, DisabledButton } from '../../../components/Buttons';
import RegimeIVAOptions from '../../../options/RegimeIVAOptions';
import ValutaOptions from '../../../options/ValutaOptions';

import { useDispatch, useSelector } from 'react-redux';
import { createInvoice } from '../../../store/orders';
import { selectLoadingStatus } from '../../../store/ui';

import axios from 'axios';

function Fatturazione() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingStatus);

  const token = useSelector((state) => state.auth.token);
  const regimi = useSelector((state) => state.orders.regimi);

  const { values, errors, handleChange, submitForm } = useFormikContext();
  const { Fatturazione: FatturazioneValues = {} } = values;
  const { Fatturazione: FatturazioneErrors = {} } = errors;

  const onSendInvoiceClick = () => {
    submitForm();

    setTimeout(() => {
      dispatch(createInvoice(values._id));
    }, 500);
  };

  const onShowInvoiceClick = async () => {
    const { FatturaId } = FatturazioneValues;
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/${FatturaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.open(data, '_blank');
  };

  return (
    <React.Fragment>
      <FormGroupTitle>Fatturazione</FormGroupTitle>
      <FormRow>
        <Select
          label="Valuta"
          name="Fatturazione.Valuta"
          value={FatturazioneValues.Valuta}
          onChange={handleChange}
          errorLabel={FatturazioneErrors.Valuta}>
          <option value=""></option>
          <ValutaOptions />
        </Select>

        <Select
          label="Regime IVA"
          name="Fatturazione.RegimeIVA"
          value={FatturazioneValues.RegimeIVA}
          onChange={handleChange}
          errorLabel={FatturazioneErrors.RegimeIVA}>
          <option value=""></option>
          {regimi.map((regime) => {
            return (
              <option key={regime.cod_iva} value={regime.cod_iva}>
                {regime.valore_iva}% {regime.descrizione_iva ? `- ${regime.descrizione_iva}` : null}
              </option>
            );
          })}
        </Select>

        <Select
          label="Gestione Prezzi"
          name="Fatturazione.IvaInclusa"
          value={FatturazioneValues.IvaInclusa}
          onChange={handleChange}
          errorLabel={FatturazioneErrors.IvaInclusa}>
          <option value=""></option>
          <option value={true}>Prezzi al lordo dell'iva</option>
          <option value={false}>Prezzi al netto dell'iva</option>
        </Select>
      </FormRow>

      <FormRow>
        <TextArea
          label="Note"
          name="Fatturazione.Note"
          value={FatturazioneValues.Note}
          onChange={handleChange}
          errorLabel={FatturazioneErrors.Note}
        />
      </FormRow>
      <FormRow>
        {FatturazioneValues.FatturaId ? (
          <ConfirmButton type="button" onClick={onShowInvoiceClick}>
            Visualizza Fattura
          </ConfirmButton>
        ) : (
          <ConfirmButton
            type="button"
            disabled={loading || Object.keys(errors).length > 0}
            onClick={onSendInvoiceClick}>
            Emetti fattura
          </ConfirmButton>
        )}
      </FormRow>
    </React.Fragment>
  );
}

export default Fatturazione;

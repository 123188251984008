import * as Yup from 'yup';

export const SpecialitaSchema = Yup.object().shape({
  Attivita: Yup.string().required('Campo obbligatorio'),
  Materia: Yup.string().required('Campo obbligatorio'),
  LinguaSource: Yup.string().required('Campo obbligatorio'),
  LinguaTarget: Yup.string().required('Campo obbligatorio'),
  UnitaMisura: Yup.string().required('Campo obbligatorio'),
  Prezzo: Yup.string().required('Campo obbligatorio'),
});

export const CollaboratorSchema = Yup.object().shape({
  Anagrafica: Yup.object()
    .shape({
      RagioneSociale: Yup.string().required('Campo obbligatorio'),
      Indirizzo: Yup.string().required('Campo obbligatorio'),
      Comune: Yup.string().required('Campo obbligatorio'),
      Provincia: Yup.string().required('Campo obbligatorio'),
      CAP: Yup.string().required('Campo obbligatorio'),
      Stato: Yup.string().required('Campo obbligatorio'),
      Telefono: Yup.string().required('Campo obbligatorio'),
      Cellulare: Yup.string().required('Campo obbligatorio'),
      Email: Yup.string().email('Inserisci una Email valida').required('Campo obbligatorio'),
      Tecnologie: Yup.string().required('Campo obbligatorio'),
    })
    .required(),
  Amministrazione: Yup.object()
    .shape({
      PartitaIVA: Yup.string().required('Campo obbligatorio'),
      CodiceFiscale: Yup.string().required('Campo obbligatorio'),
      DataNascita: Yup.string().required('Campo obbligatorio'),
      ComuneNascita: Yup.string().required('Campo obbligatorio'),
      ProvinciaNascita: Yup.string().required('Campo obbligatorio'),
      StatoNascita: Yup.string().required('Campo obbligatorio'),
      CondizioniPagamento: Yup.string().required('Campo obbligatorio'),
      IBAN: Yup.string().required('Campo obbligatorio'),
      Valuta: Yup.string().required('Campo obbligatorio'),
    })
    .required(),
  Specialita: Yup.array().of(SpecialitaSchema).required(),
});

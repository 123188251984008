import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectAuthState } from '../store/auth';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector(selectAuthState);

  return (
    <Route
      render={(props) => {
        if (isAuthenticated) {
          return <Redirect to="/dashboard" />;
        }

        return <Component {...props} />;
      }}
      {...rest}
    />
  );
};

export default PublicRoute;

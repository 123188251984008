import React from 'react';
import NATIONS from '../utils/nations';

function CountryOptions() {
  return NATIONS.map(({ code, name }, index) => (
    <option key={index} value={code}>
      {name}
    </option>
  ));
}

export default CountryOptions;

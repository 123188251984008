import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';

import { useSelector } from 'react-redux';
import { selectLoadingStatus } from '../store/ui';

const Spinner = () => {
  const isLoading = useSelector(selectLoadingStatus);

  return (
    <BarLoader
      size={150}
      css={css`
        display: block;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 100;
      `}
      loading={isLoading}
    />
  );
};

export default Spinner;

import React from 'react';

const CONDIZIONI_PAGAMENTO = [
  '100% compens. circuito Ibcredits - ibar IT2651X0BZ',
  '100% in compensazione nel circuito BexB',
  '100% in compensazione nel circuito iBARTER',
  '30 + 10gg. d.f.f.m RiBa',
  '30 gg. d.f.f.m.',
  '30 gg. d.f.f.m. RiBa',
  '60 gg. d.f.f.m. RiBa',
  '60 gg. data fattura RiBa',
  '60 + 10 gg. d.f.f.m.',
  '60 + 10 gg. d.f.f.m. RiBa',
  '90 gg. d.f.f.m.',
  '90 gg. d.f.f.m. RiBa',
  'Bank Transfer within 30 days',
  'Bank Transfer within 90 days',
  'Bonifico a 70 gg. dal mese di competenza',
  'Bonifico a 85 gg. dal mese competenza',
  'Bonifico bancario',
  'Bonifico bancario 30 gg. data fattura',
  'Bonifico bancario 30 gg. d.f.f.m.',
  'Bonifico bancario 60 gg. d.f.f.m.',
  'Bonifico bancario 90 gg. d.f.f.m.',
  'Bonifico bancario alla consegna',
  'Carta di Credito',
  'Cheque 30 gg. d.f.f.m.',
  'Contanti alla consegna',
  'Rimessa diretta vista fattura',
];

function CondizioniPagamentoOptions() {
  return CONDIZIONI_PAGAMENTO.map((condizione, index) => (
    <option key={index} value={condizione}>
      {condizione}
    </option>
  ));
}

export default CondizioniPagamentoOptions;

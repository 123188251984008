import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { startOfMonth } from 'date-fns';

import {
  FiltersContainer,
  FiltersText,
  FiltersInputContainer,
  FiltersResetContainer,
  FiltersResetButton,
} from '../../styles';

import Input from '../../../components/Input';

function Filters({ query, setQuery }) {
  const { values, setValues, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: {
      search: query.search,
      startDate: query.startDate,
      endDate: query.endDate,
    },
    onSubmit: (values) => {
      setQuery({ ...values, offset: 0 });
    },
  });

  const [debouncedHandleSubmit] = useDebouncedCallback(handleSubmit, 400);

  useEffect(() => {
    debouncedHandleSubmit();
  }, [values]);

  const onResetFilters = () => {
    setValues({
      search: '',
      startDate: startOfMonth(new Date()).toISOString().substring(0, 10),
      endDate: new Date().toISOString().substring(0, 10),
    });

    resetForm({ endDate: new Date().toISOString().substring(0, 10) });
  };

  return (
    <FiltersContainer>
      <FiltersText>FILTRI</FiltersText>
      <FiltersInputContainer>
        <Input
          type="text"
          label="Ragione sociale"
          placeholder="Cerca..."
          name="search"
          value={values.search}
          onChange={handleChange}
        />

        <Input
          type="date"
          label="Dal"
          name="startDate"
          value={values.startDate}
          onChange={handleChange}
        />
        <Input
          type="date"
          label="Al"
          name="endDate"
          value={values.endDate}
          onChange={handleChange}
        />
      </FiltersInputContainer>
      <FiltersResetContainer>
        <FiltersResetButton onClick={onResetFilters}>Reset</FiltersResetButton>
      </FiltersResetContainer>
    </FiltersContainer>
  );
}

export default Filters;

import React from 'react';
import { InputContainer, StyledLabel, StyledTextArea, StyledInputError } from './styles';

function TextArea({ label, name, value, errorLabel, ...props }) {
  return (
    <InputContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledTextArea name={name} value={value} {...props} />
      {errorLabel && <StyledInputError>{errorLabel}</StyledInputError>}
    </InputContainer>
  );
}

export default TextArea;

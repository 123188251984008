import React, { useState, useEffect, cloneElement } from 'react';
import get from 'lodash.get';
import Modal from 'react-modal';
import { useFormik, useFormikContext } from 'formik';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormRow } from '../../../styles';

import Input from '../../../../components/Input';
import Select from '../../../../components/Select';

import { SchedaAttivitaKeys } from '../../schema';
import { ConfirmButton, CancelButton } from '../../../../components/Buttons';

import AttivitaCollaboratoreOptions from '../../../../options/AttivitaCollaboratoreOptions';
import MateriaCollaboratoreOptions from '../../../../options/MateriaCollaboratoreOptions';
import LingueOptions from '../../../../options/LingueOptions';
import UnitaMisuraOptions from '../../../../options/UnitaMisuraOptions';
import ValutaOptions from '../../../../options/ValutaOptions';
import SearchableSelect from '../../../../components/SearchableSelect';

import { fetchCollaboratorsList, selectCollaboratorsList } from '../../../../store/collaborators';

Modal.setAppElement('#modal');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
};

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const INITIAL_STATE = {
  Collaboratore: '',
  DataConsegna: '',
  DataInizio: '',
  DataPrevista: '',
  DurataStimata: '',
  LinguaSource: '',
  LinguaTarget: '',
  Prezzo: '',
  Quantita: '',
  UnitaMisura: '',
};

function AttivitaAddModal({ children }) {
  const dispatch = useDispatch();
  const Collaboratori = useSelector(selectCollaboratorsList);
  const [modalIsOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((state) => !state);

  const childrenComponents = cloneElement(children, {
    onClick: toggleOpen,
  });

  const onCancel = () => {
    resetForm();
    toggleOpen();
  };

  const onSubmit = (attivita) => {
    setContextValues({ ...contextValues, Attivita: [...contextValues.Attivita, attivita] });
    toggleOpen();
    resetForm();
  };

  const { values: contextValues, setValues: setContextValues } = useFormikContext();
  const { values, handleChange, errors, handleSubmit, resetForm, setFieldValue } = useFormik({
    validateOnChange: true,
    initialValues: { ...INITIAL_STATE },
    // validationSchema: SchedaAttivitaKeys,
    onSubmit,
  });

  const fetchCollaborators = (search) => {
    dispatch(fetchCollaboratorsList({ search }));
  };

  const formatCollaboratorOptions = () => {
    return Collaboratori.map((collaboratore) => ({
      value: collaboratore._id,
      label: get(collaboratore, 'Anagrafica.RagioneSociale'),
    }));
  };

  useEffect(() => {
    const collaborator = Collaboratori.find(
      (collaborator) => collaborator._id === values.Collaboratore?._id,
    );

    if (collaborator) {
      setFieldValue('Collaboratore', collaborator);
    }
  }, [values.Collaboratore?._id]);

  return (
    <React.Fragment>
      {childrenComponents}
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <Input
                type="date"
                label="Data Inizio"
                name="DataInizio"
                value={values.DataInizio}
                onChange={handleChange}
                errorLabel={errors && errors.DataInizio}
              />

              <Input
                type="date"
                label="Data Prevista"
                name="DataPrevista"
                value={values.DataPrevista}
                onChange={handleChange}
                errorLabel={errors && errors.DataPrevista}
              />
            </FormRow>

            <FormRow>
              <Input
                label="Durata Stimata"
                name="DurataStimata"
                value={values.DurataStimata}
                onChange={handleChange}
                errorLabel={errors && errors.DurataStimata}
              />

              <Select
                label="Servizio"
                name="Servizio"
                value={values.Servizio}
                onChange={handleChange}
                errorLabel={errors.Servizio}>
                <option value=""></option>
                <AttivitaCollaboratoreOptions />
              </Select>
            </FormRow>
            <FormRow>
              <SearchableSelect
                label="Collaboratore"
                name="Collaboratore"
                value={values.Collaboratore._id}
                placeholder="Ricerca Collaboratore..."
                setFieldValue={(field, value) => setFieldValue(`${field}._id`, value)}
                errorLabel={errors.Collaboratore?._id}
                fetchOptions={fetchCollaborators}>
                {formatCollaboratorOptions()}
              </SearchableSelect>
            </FormRow>
            <FormRow>
              <Select
                label="Lingua Source"
                name="LinguaSource"
                value={values.LinguaSource}
                onChange={handleChange}
                errorLabel={errors.LinguaSource}>
                <option value=""></option>
                <LingueOptions />
              </Select>

              <Select
                label="Lingua Target"
                name="LinguaTarget"
                value={values.LinguaTarget}
                onChange={handleChange}
                errorLabel={errors.LinguaTarget}>
                <option value=""></option>
                <LingueOptions />
              </Select>

              <Select
                label="Unità di Misura"
                name="UnitaMisura"
                value={values.UnitaMisura}
                onChange={handleChange}
                errorLabel={errors.UnitaMisura}>
                <option value=""></option>
                <UnitaMisuraOptions />
              </Select>
            </FormRow>

            <FormRow>
              <Input
                label="Quantita"
                name="Quantita"
                value={values.Quantita}
                onChange={handleChange}
                errorLabel={errors && errors.Quantita}
              />

              <Input
                label="Prezzo"
                name="Prezzo"
                value={values.Prezzo}
                onChange={handleChange}
                errorLabel={errors.Prezzo}
              />
            </FormRow>

            <FormRow>
              <Input
                type="date"
                label="Data Consegna"
                name="DataConsegna"
                value={values.DataConsegna}
                onChange={handleChange}
                errorLabel={errors && errors.DataConsegna}
              />
            </FormRow>

            <ModalButtonsContainer>
              <CancelButton type="button" onClick={onCancel}>
                Annulla
              </CancelButton>
              <ConfirmButton type="submit">Conferma</ConfirmButton>
            </ModalButtonsContainer>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default AttivitaAddModal;

import React, { useState, cloneElement } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { CancelButton, ConfirmButton } from './Buttons';

Modal.setAppElement('#modal');

const customStyles = {
  content: {
    width: '500px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
};

const ModalBody = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem 0;
  font-size: 1.5rem;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

function ModalConfirm({ onConfirm, body, children }) {
  const [modalIsOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen((state) => !state);

  const childrenComponents = cloneElement(children, {
    onClick: toggleOpen,
  });

  return (
    <React.Fragment>
      {childrenComponents}
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <ModalBody>{body}</ModalBody>
        <ModalButtonsContainer>
          <CancelButton onClick={toggleOpen} type="button">
            Annulla
          </CancelButton>
          <ConfirmButton
            type="button"
            onClick={() => {
              toggleOpen();
              onConfirm();
            }}>
            Conferma
          </ConfirmButton>
        </ModalButtonsContainer>
      </Modal>
    </React.Fragment>
  );
}

export default ModalConfirm;

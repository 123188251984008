import React from 'react';
import { ListHeader, ListHeaderText, ListContainer, ListBodyContainer } from '../../styles';

import ListItem from './ListItem';

function List({ data = [] }) {
  return (
    <ListContainer>
      <ListHeader>
        <ListHeaderText>Ragione Sociale</ListHeaderText>
        <ListHeaderText>Comune</ListHeaderText>
        <ListHeaderText>CAP</ListHeaderText>
        <ListHeaderText>Stato</ListHeaderText>
        <ListHeaderText>Provincia</ListHeaderText>
        <ListHeaderText>Cellulare</ListHeaderText>
        <ListHeaderText>Email</ListHeaderText>
      </ListHeader>

      <ListBodyContainer>
        {data.map((item, index) => (
          <ListItem key={index} item={item} />
        ))}
      </ListBodyContainer>
    </ListContainer>
  );
}

export default List;

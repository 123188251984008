import React from 'react';
import { InputContainer, StyledInput, StyledLabel, StyledInputError } from './styles';

function Input({ label, name, value, errorLabel, ...props }) {
  return (
    <InputContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput name={name} value={value} {...props} />
      {errorLabel && <StyledInputError>{errorLabel}</StyledInputError>}
    </InputContainer>
  );
}

export default Input;

import React from 'react';

import { useFormikContext } from 'formik';
import ReactTooltip from 'react-tooltip';

import {
  FormGroupTitle,
  ListContainer,
  ListHeader,
  ListHeaderText,
  ListBodyContainer,
  ListBodyItem,
  ListBodyItemText,
  AddContainer,
  NoDataContainer,
  StyledDeleteIcon,
  StyledEditIcon,
} from '../../../styles';

import { AddButton } from '../../../../components/styles';
import ModalConfirm from '../../../../components/ModalConfirm';
import SpecialitaEditModal from './SpecialitaEditModal';
import SpecialitaAddModal from './SpecialitaAddModal';

import { ATTIVITA } from '../../../../options/AttivitaCollaboratoreOptions';
import { MATERIA } from '../../../../options/MateriaCollaboratoreOptions';
import { LINGUE } from '../../../../options/LingueOptions';
import { UNITA } from '../../../../options/UnitaMisuraOptions';

function Specialita() {
  const { values, setValues } = useFormikContext();

  const { Specialita = [], Amministrazione } = values;

  const onSpecialitaDelete = (specialitaIndex) => {
    setValues({
      ...values,
      Specialita: Specialita.filter((_, index) => index !== specialitaIndex),
    });
  };

  return (
    <React.Fragment>
      <FormGroupTitle>
        Specialita
        <SpecialitaAddModal>
          <AddButton data-tip="Aggiungi una specialita" style={{ color: ' #95bc00' }} />
        </SpecialitaAddModal>
      </FormGroupTitle>

      <AddContainer>
        <ListHeader>
          <ListHeaderText>Attivita</ListHeaderText>
          <ListHeaderText>Materia</ListHeaderText>
          <ListHeaderText>Lingua Source</ListHeaderText>
          <ListHeaderText>Lingua Target</ListHeaderText>
          <ListHeaderText>Prezzo</ListHeaderText>
          <ListHeaderText>Unità di Misura</ListHeaderText>
          <ListHeaderText></ListHeaderText>
        </ListHeader>

        <ListContainer>
          {Specialita.length > 0 ? (
            <ListBodyContainer noScroll>
              {Specialita.map((specialita, index) => {
                const { Valuta } = Amministrazione;
                const { Attivita, Materia, LinguaSource, LinguaTarget, UnitaMisura, Prezzo } =
                  specialita;

                return (
                  <ListBodyItem key={index}>
                    <ListBodyItemText>{ATTIVITA[Attivita]}</ListBodyItemText>
                    <ListBodyItemText>{MATERIA[Materia]}</ListBodyItemText>
                    <ListBodyItemText>{LINGUE[LinguaSource]}</ListBodyItemText>
                    <ListBodyItemText>{LINGUE[LinguaTarget]}</ListBodyItemText>
                    <ListBodyItemText>
                      {Prezzo} {Valuta}
                    </ListBodyItemText>
                    <ListBodyItemText>{UNITA[UnitaMisura]}</ListBodyItemText>
                    <ListBodyItemText>
                      <SpecialitaEditModal specialitaIndex={index}>
                        <StyledEditIcon />
                      </SpecialitaEditModal>
                      <ModalConfirm
                        body="Sei sicuro di voler eliminare la specialità?"
                        onConfirm={() => onSpecialitaDelete(index)}>
                        <StyledDeleteIcon />
                      </ModalConfirm>
                    </ListBodyItemText>
                  </ListBodyItem>
                );
              })}
            </ListBodyContainer>
          ) : (
            <NoDataContainer>
              <p>Nessuna specialita</p>
            </NoDataContainer>
          )}
        </ListContainer>

        <ReactTooltip />
      </AddContainer>
    </React.Fragment>
  );
}

export default Specialita;

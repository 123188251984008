import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import Input from './Input';
import { selectAuthState } from '../store/auth';

export const CompanyInputContainer = styled.div`
  position: relative;
`;

export const AutoCompleteListContainer = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100%;
  color: #1c1c1c;
  font-weight: 500;
  border: 1px solid #1c1c1c;

  ul {
    list-style: none;

    li {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #95bc00;
      }
    }
  }
`;

function ComuneAutoComplete({ label, name, value, errorLabel, onChange, onFetchComplete }) {
  const { token } = useSelector(selectAuthState);
  const [data, setData] = React.useState([]);
  const [debouncedFetch] = useDebouncedCallback(fetchData, 800);

  async function fetchData() {
    if (value.length < 1) return setData([]);

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/places`,
      {},
      { headers: { Authorization: `Bearer ${token}` }, params: { input: value } },
    );

    setData(data);
  }

  React.useEffect(() => {
    function listener() {
      setData([]);
    }

    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, []);

  async function getSelectedPlace({ place_id }) {
    setData([]);

    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/places/${place_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    onFetchComplete(data);
  }

  return (
    <CompanyInputContainer>
      <Input
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={debouncedFetch}
        autoComplete="none"
        errorLabel={errorLabel}
      />
      {data.length > 0 && (
        <AutoCompleteListContainer>
          <ul>
            {data.map((place, index) => (
              <li key={index} onClick={() => getSelectedPlace(place)}>
                {place.description}
              </li>
            ))}
          </ul>
        </AutoCompleteListContainer>
      )}
    </CompanyInputContainer>
  );
}

export default ComuneAutoComplete;

import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LogOut } from '@styled-icons/boxicons-regular/';

import ModalConfirm from './ModalConfirm';
import { logout } from '../store/auth';

const LogoutButtonContainer = styled.div`
  height: 80px;
  padding: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoutIcon = styled(LogOut)`
  color: #a72608;
  width: 40px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

function Logout() {
  const dispatch = useDispatch();

  return (
    <LogoutButtonContainer>
      <ModalConfirm onConfirm={() => dispatch(logout())} body="Sei sicuro di voler uscire?">
        <LogoutIcon />
      </ModalConfirm>
    </LogoutButtonContainer>
  );
}

export default Logout;

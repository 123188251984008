import React from 'react';
import { ListHeader, ListHeaderText, ListContainer, ListBodyContainer } from '../../styles';

import ListItem from './ListItem';

function List({ data = [] }) {
  return (
    <ListContainer>
      <ListHeader>
        <ListHeaderText>Cliente</ListHeaderText>
        <ListHeaderText>Referente</ListHeaderText>
        <ListHeaderText>Codice</ListHeaderText>
        <ListHeaderText>Data</ListHeaderText>
        <ListHeaderText>Descrizione</ListHeaderText>
        <ListHeaderText>Responsabile</ListHeaderText>
        <ListHeaderText>Materia</ListHeaderText>
      </ListHeader>

      <ListBodyContainer>
        {data.map((item, index) => (
          <ListItem key={index} item={item} />
        ))}
      </ListBodyContainer>
    </ListContainer>
  );
}

export default List;

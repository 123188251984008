import { createSlice } from '@reduxjs/toolkit';
import { fetchData } from './api';

const INITIAL_STATE = {
  list: [],
  total: 0,
  current: {
    Anagrafica: {
      RagioneSociale: '',
      Indirizzo: '',
      Comune: '',
      CAP: '',
      Provincia: '',
      Stato: '',
      Telefono: '',
      Cellulare: '',
      Fax: '',
      Email: '',
      Web: '',
      Tecnologie: '',
      Titoli: '',
      Note: '',
    },
    Amministrazione: {
      PartitaIVA: '',
      CodiceFiscale: '',
      DataNascita: '',
      ComuneNascita: '',
      ProvinciaNascita: '',
      StatoNascita: '',
      CondizioniPagamento: '',
      Valuta: '',
      Banca: '',
      Agenzia: '',
      IBAN: '',
    },
    Specialita: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'collaborators',
  initialState: INITIAL_STATE,
  reducers: {
    fetchedCollaboratorsList: (state, { payload }) => {
      state.list = payload.collaborators;
      state.total = payload.total;
    },
    fetchedCollaborator: (state, { payload }) => {
      state.current = payload;
    },
    savedCollaborator: (state, { payload }) => {
      state.current = payload;
    },
    createdCollaborator: (state, { payload }) => {
      state.current = payload;
    },
    resetCollaborator: (state) => {
      state.current = INITIAL_STATE.current;
    },
  },
});

export const {
  fetchedCollaboratorsList,
  fetchedCollaborator,
  savedCollaborator,
  createdCollaborator,
  resetCollaborator,
} = actions;

export default reducer;

// Actions
export const fetchCollaboratorsList = (params) => {
  return fetchData({
    url: '/collaborators',
    params,
    onSuccess: actions.fetchedCollaboratorsList.type,
  });
};

export const fetchCollaboratorById = (id) => {
  return fetchData({
    url: `/collaborators/${id}`,
    onSuccess: actions.fetchedCollaborator.type,
  });
};

export const saveCollaborator = (collaborator) => {
  return fetchData({
    url: `/collaborators/${collaborator._id}`,
    method: 'PUT',
    data: collaborator,
    onSuccess: actions.savedCollaborator.type,
    onSuccessAlertMessage: {
      message: 'Salvataggio effettuato con successo!',
      type: 'success',
    },
  });
};

export const createCollaborator = (collaborator) => {
  return fetchData({
    url: `/collaborators`,
    method: 'POST',
    data: collaborator,
    onSuccess: actions.createdCollaborator.type,
    onSuccessAlertMessage: {
      message: 'Creazione effettuata con successo!',
      type: 'success',
    },
  });
};

export const deleteCollaborator = (_id) => {
  return fetchData({
    url: `/collaborators/${_id}`,
    method: 'DELETE',
    redirectUri: `/collaborators`,
    onSuccess: actions.resetCollaborator.type,
    onSuccessAlertMessage: {
      message: 'Eliminazione effettuata con successo!',
      type: 'success',
    },
  });
};

// Selectors
export const selectCollaboratorsList = ({ collaborators }) => {
  return collaborators.list;
};

export const selectCollaboratorsTotal = ({ collaborators }) => {
  return collaborators.total;
};

export const selectCurrentCollaborator = ({ collaborators }) => {
  return collaborators.current;
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import { ListBodyItem, ListBodyItemText } from '../../styles';

function ListItem({ item = {} }) {
  const history = useHistory();

  const { _id, Anagrafica = {} } = item;

  const goToDetailPage = () => {
    history.push(`/client/${_id}`);
  };

  const { RagioneSociale, Comune, CAP, Stato, Provincia, Cellulare, Email } = Anagrafica;

  return (
    <ListBodyItem onClick={goToDetailPage}>
      <ListBodyItemText>{RagioneSociale}</ListBodyItemText>
      <ListBodyItemText>{Comune}</ListBodyItemText>
      <ListBodyItemText>{CAP}</ListBodyItemText>
      <ListBodyItemText>{Stato}</ListBodyItemText>
      <ListBodyItemText>{Provincia}</ListBodyItemText>
      <ListBodyItemText>{Cellulare}</ListBodyItemText>
      <ListBodyItemText>{Email}</ListBodyItemText>
    </ListBodyItem>
  );
}

export default ListItem;

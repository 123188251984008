import axios from 'axios';
import { fetchData } from '../api';
import { startLoading, stopLoading, setAlert } from '../ui';

import history from '../../routes/history';

export default ({ dispatch, getState }) => (next) => async (action) => {
  if (action.type !== fetchData.type) return next(action);

  dispatch(startLoading());

  const {
    url,
    method = 'GET',
    data = {},
    params = {},
    redirectUri,
    onSuccess,
    onSuccessAlertMessage,
  } = action.payload;

  const { auth = {} } = getState();
  const { token = '' } = auth;

  try {
    const response = await axios({
      baseURL: process.env.REACT_APP_API_URL,
      url,
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
      params,
    });

    if (redirectUri) {
      history.replace(redirectUri);
    }

    if (onSuccessAlertMessage) {
      dispatch(setAlert(onSuccessAlertMessage));
    }

    dispatch({ type: onSuccess, payload: response.data });
  } catch (error) {
    dispatch(setAlert({ message: "Qualcosa e' andato storto...", type: 'error' }));
  } finally {
    dispatch(stopLoading());
  }
};

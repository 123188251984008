import { createSlice } from '@reduxjs/toolkit';

const { reducer } = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {},
});

// export const {} = actions;
export default reducer;

import React from 'react';

export const LINGUE = {
  AR: 'ARABO',
  BG: 'BULGARO',
  BS: 'BOSNIACO',
  CA: 'CATALANO',
  CS: 'CECO',
  DA: 'DANESE',
  DE: 'TEDESCO',
  EL: 'GRECO',
  EN: 'INGLESE',
  ES: 'SPAGNOLO',
  ET: 'ESTONE',
  FA: 'FARSI',
  FI: 'FINLANDESE',
  FR: 'FRANCESE',
  HE: 'EBRAICO',
  HR: 'CROATO',
  HU: 'UNGHERESE',
  IS: 'ISLANDESE',
  IT: 'ITALIANO',
  JA: 'GIAPPONESE',
  KO: 'COREANO',
  LT: 'LITUANO',
  LV: 'LETTONE',
  MK: 'MACEDONE',
  NL: 'OLANDESE',
  NO: 'NORVEGESE',
  PL: 'POLACCO',
  PT: 'PORTOGHESE',
  RO: 'RUMENO',
  RU: 'RUSSO',
  SK: 'SLOVACCO',
  SL: 'SLOVENO',
  SQ: 'ALBANESE',
  SR: 'SERBO',
  SV: 'SVEDESE',
  TH: 'THAI',
  TR: 'TURCO',
  UK: 'UCRAINO',
  VI: 'VIETNAMITA',
  ZH: 'CINESE',
};

function LingueOptions() {
  return Object.entries(LINGUE).map(([key, value], index) => (
    <option key={index} value={key}>
      {value}
    </option>
  ));
}

export default LingueOptions;

import React from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import ModalConfirm from '../../../components/ModalConfirm';
import { SaveButton, DeleteButton } from '../../../components/Buttons';

const ReferentiButtons = styled.div`
  justify-self: flex-end;

  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

function Buttons({ data = {}, onDeleteConfirm }) {
  const { submitForm } = useFormikContext();

  const isEditing = Boolean(data._id);

  return (
    <ReferentiButtons>
      {isEditing ? (
        <ModalConfirm body="Confermi l'eliminazione?" onConfirm={onDeleteConfirm}>
          <DeleteButton type="button">Elimina</DeleteButton>
        </ModalConfirm>
      ) : (
        <div />
      )}

      <SaveButton type="button" onClick={submitForm}>
        Salva
      </SaveButton>
    </ReferentiButtons>
  );
}

export default Buttons;

import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';
import { loginUser } from '../../store/auth';

import { Form, FormGroupTitle } from '../styles';
import Logo from '../../components/Logo';
import Input from '../../components/Input';
import { ConfirmButton } from '../../components/Buttons';

import { LoginSchema } from './schema';

const LoginContainer = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginFormContainer = styled.div`
  width: 500px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

function Login() {
  const dispatch = useDispatch();

  const { values, errors, handleChange, handleSubmit } = useFormik({
    validateOnChange: true,
    initialValues: { Email: '', Password: '' },
    validationSchema: LoginSchema,
    onSubmit: (data) => dispatch(loginUser(data)),
  });

  return (
    <LoginContainer>
      <Logo />
      <LoginFormContainer>
        <Form onSubmit={handleSubmit} style={{ padding: '2rem' }}>
          <FormGroupTitle>Login</FormGroupTitle>

          <Input
            type="email"
            label="Email"
            name="Email"
            value={values.Email}
            onChange={handleChange}
            errorLabel={errors.Email}
          />
          <Input
            type="password"
            label="Password"
            name="Password"
            value={values.Password}
            onChange={handleChange}
            errorLabel={errors.Password}
          />
          <ConfirmButton type="submit">Login</ConfirmButton>
        </Form>
      </LoginFormContainer>
    </LoginContainer>
  );
}

export default Login;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';

import { fetchClientsList, selectClientsList, selectClientsTotal } from '../../../store/clients';

import { PageContainer } from '../../styles';
import { Group } from '@styled-icons/boxicons-solid/';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Filters from './Filters';
import List from './List';
import Pagination from '../../../components/Pagination';

function ClientsList() {
  const dispatch = useDispatch();
  const clients = useSelector(selectClientsList);
  const total = useSelector(selectClientsTotal);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    country: withDefault(StringParam, ''),
    fromDate: withDefault(StringParam, ''),
    toDate: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 30),
  });

  React.useEffect(() => {
    dispatch(fetchClientsList(query));
  }, [query]);

  function resetFilters() {
    setQuery({ search: '', country: '', fromDate: '', toDate: '', offset: 0 });
  }

  return (
    <Layout>
      <Header
        text="CLIENTI"
        TextIcon={Group}
        buttonTooltipText="Aggiungi un nuovo cliente"
        buttonRedirectUri="/client"
      />
      <PageContainer>
        <Filters query={query} setQuery={setQuery} resetFilters={resetFilters} />
        <List data={clients} />
        <Pagination
          total={total}
          limit={query.limit}
          offset={query.offset}
          onPageChange={(offset) => setQuery({ offset })}
        />
      </PageContainer>
    </Layout>
  );
}

export default ClientsList;

import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import {
  FiltersContainer,
  FiltersText,
  FiltersInputContainer,
  FiltersResetContainer,
  FiltersResetButton,
} from '../../styles';

import Input from '../../../components/Input';
import Select from '../../../components/Select';

import LingueOptions from '../../../options/LingueOptions';
import MateriaCollaboratoreOptions from '../../../options/MateriaCollaboratoreOptions';

function Filters({ query, setQuery, resetFilters }) {
  const [search, setSearch] = useState('');
  const [searchText] = useDebounce(search, 400);

  useEffect(() => {
    setQuery({ search, offset: 0 });
  }, [searchText]);

  const onResetFilters = () => {
    setSearch('');
    resetFilters();
  };

  return (
    <FiltersContainer>
      <FiltersText>FILTRI</FiltersText>
      <FiltersInputContainer>
        <Input
          label="Ragione sociale"
          placeholder="Cerca..."
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />

        <Select
          label="Lingua Source"
          value={query.source}
          onChange={({ target }) => setQuery({ source: target.value, offset: 0 })}>
          <option value=""></option>
          <LingueOptions />
        </Select>

        <Select
          label="Lingua Target"
          value={query.target}
          onChange={({ target }) => setQuery({ target: target.value, offset: 0 })}>
          <option value=""></option>
          <LingueOptions />
        </Select>

        <Select
          label="Materia"
          value={query.materia}
          onChange={({ target }) => setQuery({ materia: target.value, offset: 0 })}>
          <option value=""></option>
          <MateriaCollaboratoreOptions />
        </Select>
      </FiltersInputContainer>
      <FiltersResetContainer>
        <FiltersResetButton onClick={onResetFilters}>Reset</FiltersResetButton>
      </FiltersResetContainer>
    </FiltersContainer>
  );
}

export default Filters;

import React from 'react';
import { useFormikContext } from 'formik';

import { FormGroupTitle, FormRow } from '../../styles';

import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import ComuneAutoComplete from '../../../components/ComuneAutoComplete';
import Select from '../../../components/Select';
import NationOptions from '../../../options/NationOptions';

function Anagrafica() {
  const { values, errors, handleChange, setValues } = useFormikContext();

  function setLocationData(data) {
    setValues({
      ...values,
      Anagrafica: { ...AnagraficaValues, ...data },
    });
  }

  const { Anagrafica: AnagraficaValues = {} } = values;
  const { Anagrafica: AnagraficaErrors = {} } = errors;

  return (
    <React.Fragment>
      <FormGroupTitle>Anagrafica</FormGroupTitle>
      <FormRow>
        <Input
          label="Ragione Sociale"
          name="Anagrafica.RagioneSociale"
          value={AnagraficaValues.RagioneSociale}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.RagioneSociale}
        />
        <Input
          label="Indirizzo"
          name="Anagrafica.Indirizzo"
          value={AnagraficaValues.Indirizzo}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.Indirizzo}
        />
      </FormRow>

      <FormRow>
        <ComuneAutoComplete
          label="Comune"
          name="Anagrafica.Comune"
          value={AnagraficaValues.Comune}
          onChange={handleChange}
          onFetchComplete={setLocationData}
          errorLabel={AnagraficaErrors.Comune}
        />
        <Input
          label="Provincia"
          name="Anagrafica.Provincia"
          value={AnagraficaValues.Provincia}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.Provincia}
        />
        <Input
          label="CAP"
          name="Anagrafica.CAP"
          value={AnagraficaValues.CAP}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.CAP}
        />
        <Select
          label="Stato"
          name="Anagrafica.Stato"
          value={AnagraficaValues.Stato}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.Stato}>
          <option value=""></option>
          <NationOptions />
        </Select>
      </FormRow>

      <FormRow>
        <Input
          label="Telefono"
          name="Anagrafica.Telefono"
          value={AnagraficaValues.Telefono}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.Telefono}
        />
        <Input
          label="Cellulare"
          name="Anagrafica.Cellulare"
          value={AnagraficaValues.Cellulare}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.Cellulare}
        />
        <Input
          label="Email"
          name="Anagrafica.Email"
          value={AnagraficaValues.Email}
          onChange={handleChange}
          errorLabel={AnagraficaErrors.Email}
        />
        <Input
          label="Fax"
          name="Anagrafica.Fax"
          value={AnagraficaValues.Fax}
          onChange={handleChange}
        />
        <Input
          label="Web"
          name="Anagrafica.Web"
          value={AnagraficaValues.Web}
          onChange={handleChange}
        />
      </FormRow>

      <FormRow>
        <TextArea
          label="Note"
          name="Anagrafica.Note"
          value={AnagraficaValues.Note}
          onChange={handleChange}
        />
      </FormRow>
    </React.Fragment>
  );
}

export default Anagrafica;

import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import { DoughnutChart, Group, User } from '@styled-icons/boxicons-solid/';
import { FileInvoiceDollar } from '@styled-icons/fa-solid';
import { DocumentText } from '@styled-icons/heroicons-solid';

import { NavContainer, IconsContainer, StyledLink } from './styles';

import Logo from './Logo';
import Logout from './Logout';

function NavBar() {
  const { pathname } = useLocation();

  const setBackgroundColor = (routeName) => (pathname.startsWith(routeName) ? '#95BC00' : '');
  const setActiveColor = (routeName) => (pathname.startsWith(routeName) ? '#FFFFFF' : '#3C4A64');

  return (
    <NavContainer>
      <Logo />

      <IconsContainer>
        <StyledLink
          to="/dashboard"
          backgroundcolor={setBackgroundColor('/dashboard')}
          data-tip="Dashboard">
          <DoughnutChart size={40} color={setActiveColor('/dashboard')} />
        </StyledLink>

        <StyledLink
          to="/clients"
          backgroundcolor={setBackgroundColor('/client')}
          data-tip="Clienti">
          <Group size={40} color={setActiveColor('/client')} />
        </StyledLink>

        <StyledLink
          to="/collaborators"
          backgroundcolor={setBackgroundColor('/collaborator')}
          data-tip="Collaboratori">
          <User size={40} color={setActiveColor('/collaborator')} />
        </StyledLink>

        <StyledLink to="/orders" backgroundcolor={setBackgroundColor('/order')} data-tip="Commesse">
          <DocumentText size={40} color={setActiveColor('/order')} />
        </StyledLink>

        {/* <StyledLink
          to="/invoices"
          backgroundcolor={setBackgroundColor('/invoices')}
          data-tip="Fatture">
          <FileInvoiceDollar size={40} color={setActiveColor('/invoices')} />
        </StyledLink> */}
      </IconsContainer>

      <Logout />
      <ReactTooltip />
    </NavContainer>
  );
}

export default NavBar;

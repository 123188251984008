import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectAuthState } from '../store/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector(selectAuthState);

  return (
    <Route
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }

        return <Redirect to="/login" />;
      }}
      {...rest}
    />
  );
};

export default PrivateRoute;

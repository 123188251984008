import React from 'react';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import { PageContainer } from '../styles';
import { DoughnutChart } from '@styled-icons/boxicons-solid/';

function Dashboard() {
  return (
    <Layout>
      <Header text="DASHBOARD" TextIcon={DoughnutChart} />
      <PageContainer></PageContainer>
    </Layout>
  );
}

export default Dashboard;

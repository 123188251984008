import * as Yup from 'yup';
import { ClientSchema } from '../../Clients/schema';
import { CollaboratorSchema } from '../../Collaborators/schema';

const ClientId = Yup.object().shape({
  _id: Yup.string().required('Campo obbligatorio'),
});

const GeneraleKeys = {
  Cliente: ClientId,
  Referente: Yup.string().required('Campo obbligatorio'),
  Codice: Yup.string().required('Campo obbligatorio'),
  Data: Yup.date().required('Campo obbligatorio'),
  Descrizione: Yup.string().required('Campo obbligatorio'),
  Responsabile: Yup.string().required('Campo obbligatorio'),
  Materia: Yup.string().required('Campo obbligatorio'),
  DataRichiesta: Yup.date().required('Campo obbligatorio'),
  DataConsegna: Yup.date().required('Campo obbligatorio'),
};

const NoteKeys = {
  Generali: Yup.string().required('Campo obbligatorio'),
  Collaboratore: Yup.string().required('Campo obbligatorio'),
};

export const SchedaServizioKeys = Yup.object().shape({
  DataInizio: Yup.date().required('Campo obbligatorio'),
  DataPrevista: Yup.date().required('Campo obbligatorio'),
  Servizio: Yup.string().required('Campo obbligatorio'),
  LinguaSource: Yup.string().required('Campo obbligatorio'),
  LinguaTarget: Yup.string().required('Campo obbligatorio'),
  UnitaMisura: Yup.string().required('Campo obbligatorio'),
  Quantita: Yup.number().required('Campo obbligatorio'),
  Prezzo: Yup.string().required('Campo obbligatorio'),
  DataConsegna: Yup.date().required('Campo obbligatorio'),
  QuantitaConsegnata: Yup.number().required('Campo obbligatorio'),
});

export const SchedaAttivitaKeys = Yup.object().shape({
  DataInizio: Yup.date().required('Campo obbligatorio'),
  DataPrevista: Yup.date().required('Campo obbligatorio'),
  DurataStimata: Yup.number().required('Campo obbligatorio'),
  Servizio: Yup.string().required('Campo obbligatorio'),
  LinguaSource: Yup.string().required('Campo obbligatorio'),
  LinguaTarget: Yup.string().required('Campo obbligatorio'),
  Collaboratore: ClientId,
  UnitaMisura: Yup.string().required('Campo obbligatorio'),
  Prezzo: Yup.string().required('Campo obbligatorio'),
  DataConsegna: Yup.date().required('Campo obbligatorio'),
});

const FatturazioneKeys = Yup.object().shape({
  Valuta: Yup.string().required('Campo obbligatorio'),
  RegimeIVA: Yup.string().required('Campo obbligatorio'),
  IvaInclusa: Yup.boolean().required('Campo obbligatorio'),
  Note: Yup.string().required('Campo obbligatorio'),
});

export const OrderSchema = Yup.object().shape({
  Generale: Yup.object().shape(GeneraleKeys),
  Note: Yup.object().shape(NoteKeys),
  Servizi: Yup.array().of(SchedaServizioKeys),
  Attivita: Yup.array().of(SchedaAttivitaKeys),
  // Fatturazione: FatturazioneKeys,
  createdAt: Yup.date(),
});

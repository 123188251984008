import React from 'react';
import styled from 'styled-components';

import { ListBodyItem, ListBodyItemText } from '../../styles';

const Link = styled.a`
  text-decoration: none;
`;

function ListItem({ item = {} }) {
  const { numero, data, nome, valuta, importo_netto, importo_totale, link_doc } = item;

  const symbol = valuta === 'EUR' ? '€' : '$';
  const year = data.split('/')[2];

  return (
    <Link href={link_doc} target="_blank">
      <ListBodyItem>
        <ListBodyItemText>{`${numero}/${year}`}</ListBodyItemText>
        <ListBodyItemText>{data}</ListBodyItemText>
        <ListBodyItemText col={5}>{nome}</ListBodyItemText>
        <ListBodyItemText>{valuta}</ListBodyItemText>
        <ListBodyItemText>
          {symbol} {importo_netto}
        </ListBodyItemText>
        <ListBodyItemText>
          {symbol} {importo_totale}
        </ListBodyItemText>
      </ListBodyItem>
    </Link>
  );
}

export default ListItem;

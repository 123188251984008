import React from 'react';

export const ATTIVITA = {
  TRADUZIONE: 'Traduzione',
  INTERPRETARIATO: 'Interpretariato',
  REVISIONE: 'Revisione',
  MT_POST_EDITING: 'MT Post-editing',
  ASSEVERAZIONE: 'Asseverazione',
  LEGALIZZAZIONE: 'Legalizzazione',
};

function AttivitaCollaboratoreOptions() {
  return Object.entries(ATTIVITA).map(([key, value], index) => (
    <option key={index} value={key}>
      {value}
    </option>
  ));
}

export default AttivitaCollaboratoreOptions;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AddCircle } from '@styled-icons/ionicons-outline';

// HEADER

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 66px;
  padding: 0 1rem;

  background-color: #95bc00;
`;

export const HeaderText = styled.p`
  display: flex;
  align-items: center;

  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;

  color: #fff;
`;

export const AddButton = styled(AddCircle)`
  color: #fff;
  width: 60px;

  cursor: pointer;
`;

// INPUTS
export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &.error {
    border-color: red;
  }
`;

export const StyledLabel = styled.label`
  color: #1c1c1c;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const StyledInput = styled.input`
  height: 40px;
  font-family: inherit;
  font-size: 1rem;
  border: 1px solid #e7e5e5;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 5px;
`;

export const StyledInputError = styled.p`
  color: #a72608;
`;

export const StyledSelect = styled.select`
  height: 40px;
  font-family: inherit;
  font-size: 1rem;
  border: 1px solid #e7e5e5;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 5px;
`;

export const StyledTextArea = styled.textarea`
  font-family: inherit;
  font-size: 1rem;
  height: 8rem;
  resize: none;
  border: 1px solid #e7e5e5;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 5px;
`;

// NAVBAR

export const NavContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  width: 100px;

  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const LogoContainer = styled.div`
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
  }
`;

export const IconsContainer = styled.div`
  flex: 1;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    width: 100%;
    display: flex;
    align-items: center;
  }

  a:not(:first-child) {
    margin-top: 1rem;
  }

  a:hover {
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${({ backgroundcolor }) => !backgroundcolor && '#E7E5E5'};
  }
`;

// PAGINATION
export const PaginationContainer = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  background-color: #fff;
  padding: 0 1rem;

  .pagination {
    padding: 0.5rem 0;
    list-style: none;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    li {
      color: black;
      cursor: pointer;
      font-size: 1rem;
      padding: 0.2rem 0.5rem;

      a {
        outline: none;
      }
    }

    .selected {
      font-size: 1.2rem;
      color: #95bc00;
      border-radius: 5px;
      font-weight: bold;
    }

    & .previous,
    & .next {
      color: #95bc00;
      transition: 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

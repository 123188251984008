import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';

import authReducer from './auth';
import dashboardReducer from './dashboard';
import clientsReducer from './clients';
import collaboratorsReducer from './collaborators';
import ordersReducer from './orders';
import invoicesReducer from './invoices';
import uiReducer from './ui';

import api from './middlewares/api';

const reducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  clients: clientsReducer,
  collaborators: collaboratorsReducer,
  orders: ordersReducer,
  invoices: invoicesReducer,
  ui: uiReducer,
});

export default configureStore({
  reducer,
  middleware: [...getDefaultMiddleware(), api],
});

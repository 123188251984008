import 'react-tabs/style/react-tabs.css';
import React from 'react';
import { Formik } from 'formik';

import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCollaboratorById,
  selectCurrentCollaborator,
  saveCollaborator,
  resetCollaborator,
  createCollaborator,
  deleteCollaborator,
} from '../../../store/collaborators';

import GoBack from '../../../components/GoBack';
import { User } from '@styled-icons/boxicons-solid/';
import { PageContainer, Form } from '../../styles';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Anagrafica from './Anagrafica';
import Amministrazione from './Amministrazione';
import Specialita from './Specialita';
import Buttons from './Buttons';

import { CollaboratorSchema } from '../schema';

function Detail() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const collaborator = useSelector(selectCurrentCollaborator);

  // Effect per fetchare il cliente se id esiste
  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(fetchCollaboratorById(id));
    }
  }, [id]);

  // Effect per resettare il form al unmount del componente
  React.useEffect(() => () => dispatch(resetCollaborator()), []);

  // Effect per inserire l'id del collaboratore creato all'url
  React.useEffect(() => {
    if (id === undefined && collaborator._id !== undefined) {
      history.replace(`/collaborator/${collaborator._id}`);
    }
  }, [collaborator._id]);

  function onSaveClick(values) {
    if (id !== undefined) {
      return dispatch(saveCollaborator(values));
    }

    dispatch(createCollaborator(values));
  }

  function onDeleteConfirm() {
    if (collaborator._id !== undefined) {
      dispatch(deleteCollaborator(collaborator._id));
    }
  }

  return (
    <Layout>
      <Header text="COLLABORATORI" TextIcon={User} />
      <Formik
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={collaborator}
        onSubmit={onSaveClick}
        // validationSchema={CollaboratorSchema}
      >
        <PageContainer>
          <GoBack />
          <Form>
            <Anagrafica />
            <Amministrazione />
            <Specialita />
            <Buttons data={collaborator} onDeleteConfirm={onDeleteConfirm} />
          </Form>
        </PageContainer>
      </Formik>
    </Layout>
  );
}

export default Detail;

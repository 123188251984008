import React from 'react';
import { Provider, positions, transitions } from 'react-alert';
import {
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlineInfoCircle,
  AiOutlineClose,
} from 'react-icons/ai';

const alertStyle = {
  backgroundColor: '#fff',
  color: 'white',
  padding: '20px',
  textTransform: 'uppercase',
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.2)',
  fontFamily: 'Arial',
  width: '400px',
  boxSizing: 'border-box',
};

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#000',
};

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      {options.type === 'info' && <AiOutlineInfoCircle size={30} color="#000" />}
      {options.type === 'success' && <AiOutlineCheckCircle size={30} color="green" />}
      {options.type === 'error' && <AiOutlineCloseCircle size={30} color="red" />}
      <div style={{ flex: 2, marginLeft: 10, color: '#000' }}>{message}</div>
      <button onClick={close} style={buttonStyle}>
        <AiOutlineClose size={20} />
      </button>
    </div>
  );
};

const options = {
  position: positions.TOP_RIGHT,
  timeout: 3000,
  transition: transitions.SCALE,
};

const AlertProvider = ({ children }) => {
  return (
    <Provider template={AlertTemplate} {...options}>
      {children}
    </Provider>
  );
};

export default AlertProvider;

import { createSlice } from '@reduxjs/toolkit';
import { fetchData } from './api';

const INITIAL_STATE = {
  regimi: [],
  list: [],
  total: 0,
  current: {
    Generale: {
      Cliente: {},
      Referente: '',
      Codice: '',
      Data: '',
      Descrizione: '',
      Responsabile: '',
      Materia: '',
      DataRichiesta: '',
      DataEffettiva: '',
    },
    Note: {
      Generali: '',
      Collaboratore: '',
    },
    Servizi: [],
    Attivita: [],
    Fatturazione: {
      RegimeIVA: '',
      Note: '',
    },
  },
};

const { actions, reducer } = createSlice({
  name: 'orders',
  initialState: INITIAL_STATE,
  reducers: {
    fetchedOrdersList: (state, { payload }) => {
      state.list = payload.orders;
      state.total = payload.total;
    },

    fetchedRegimi: (state, { payload }) => {
      state.regimi = payload;
    },

    fetchedOrder: (state, { payload }) => {
      state.current = payload;
    },
    savedOrder: (state, { payload }) => {
      state.current = payload;
    },
    createdOrder: (state, { payload }) => {
      state.current = payload;
    },
    resetOrder: (state) => {
      state.current = INITIAL_STATE.current;
    },
    invoiceCreated: (state, { payload }) => {
      state.current = payload;
    },
  },
});

export const {
  fetchedOrdersList,
  fetchedOrder,
  savedOrder,
  createdOrder,
  resetOrder,
  invoiceCreated,
} = actions;

export default reducer;

// Actions
export const fetchOrdersList = (params) => {
  return fetchData({
    url: '/orders',
    params,
    onSuccess: actions.fetchedOrdersList.type,
  });
};

export const fetchRegimiList = () => {
  return fetchData({
    url: '/invoices/regimi',
    onSuccess: actions.fetchedRegimi.type,
  });
};

export const fetchOrderById = (id) => {
  return fetchData({
    url: `/orders/${id}`,
    onSuccess: actions.fetchedOrder.type,
  });
};

export const saveOrder = (order) => {
  return fetchData({
    url: `/orders/${order._id}`,
    method: 'PUT',
    data: order,
    onSuccess: actions.savedOrder.type,
    onSuccessAlertMessage: {
      message: 'Salvataggio effettuato con successo!',
      type: 'success',
    },
  });
};

export const createOrder = (order) => {
  return fetchData({
    url: `/orders`,
    method: 'POST',
    data: order,
    onSuccess: actions.createdOrder.type,
    onSuccessAlertMessage: {
      message: 'Creazione effettuata con successo!',
      type: 'success',
    },
  });
};

export const deleteOrder = (_id) => {
  return fetchData({
    url: `/orders/${_id}`,
    method: 'DELETE',
    redirectUri: `/orders`,
    onSuccess: actions.resetOrder.type,
    onSuccessAlertMessage: {
      message: 'Eliminazione effettuata con successo!',
      type: 'success',
    },
  });
};

export const createInvoice = (id) => {
  return fetchData({
    url: `/invoices/${id}`,
    method: 'POST',
    onSuccess: actions.invoiceCreated.type,
    onSuccessAlertMessage: {
      message: 'Fattura emessa!',
      type: 'success',
    },
  });
};

// Selectors
export const selectOrdersList = ({ orders }) => {
  return orders.list;
};

export const selectOrdersTotal = ({ orders }) => {
  return orders.total;
};

export const selectCurrentOrder = ({ orders }) => {
  return orders.current;
};

import React from 'react';

import { useFormikContext } from 'formik';
import ReactTooltip from 'react-tooltip';

import {
  FormGroupTitle,
  ListContainer,
  ListHeader,
  ListHeaderText,
  ListBodyContainer,
  ListBodyItem,
  ListBodyItemText,
  AddContainer,
  NoDataContainer,
  StyledDeleteIcon,
  StyledEditIcon,
} from '../../../styles';

import { AddButton } from '../../../../components/styles';
import ModalConfirm from '../../../../components/ModalConfirm';
import ReferenteEditModal from './ReferenteEditModal';
import ReferenteAddModal from './ReferenteAddModal';

function Referenti() {
  const { values, setValues } = useFormikContext();

  const { Referenti = [] } = values;

  const onReferenteDelete = (referenteIndex) => {
    setValues({ ...values, Referenti: Referenti.filter((_, index) => index !== referenteIndex) });
  };

  return (
    <React.Fragment>
      <FormGroupTitle>
        Referenti
        <ReferenteAddModal>
          <AddButton data-tip="Aggiungi un referente" style={{ color: ' #95bc00' }} />
        </ReferenteAddModal>
      </FormGroupTitle>

      <AddContainer>
        <ListHeader>
          <ListHeaderText>Titolo</ListHeaderText>
          <ListHeaderText>Qualifica</ListHeaderText>
          <ListHeaderText>Nome</ListHeaderText>
          <ListHeaderText>Cognome</ListHeaderText>
          <ListHeaderText>Telefono</ListHeaderText>
          <ListHeaderText>Email</ListHeaderText>
          <ListHeaderText></ListHeaderText>
        </ListHeader>
        <ListContainer>
          {Referenti.length > 0 ? (
            <ListBodyContainer noScroll>
              {Referenti.map((referente, index) => {
                const { Titolo, Nome, Cognome, Qualifica, Telefono, Email } = referente;

                return (
                  <ListBodyItem key={index}>
                    <ListBodyItemText>{Titolo}</ListBodyItemText>
                    <ListBodyItemText>{Qualifica}</ListBodyItemText>
                    <ListBodyItemText>{Nome}</ListBodyItemText>
                    <ListBodyItemText>{Cognome}</ListBodyItemText>
                    <ListBodyItemText>{Telefono}</ListBodyItemText>
                    <ListBodyItemText>{Email}</ListBodyItemText>
                    <ListBodyItemText>
                      <ReferenteEditModal referenteIndex={index}>
                        <StyledEditIcon />
                      </ReferenteEditModal>
                      <ModalConfirm
                        body="Sei sicuro di voler eliminare il referente?"
                        onConfirm={() => onReferenteDelete(index)}>
                        <StyledDeleteIcon />
                      </ModalConfirm>
                    </ListBodyItemText>
                  </ListBodyItem>
                );
              })}
            </ListBodyContainer>
          ) : (
            <NoDataContainer>
              <p>Nessun referente</p>
            </NoDataContainer>
          )}
        </ListContainer>

        <ReactTooltip />
      </AddContainer>
    </React.Fragment>
  );
}

export default Referenti;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';

import {
  fetchCollaboratorsList,
  selectCollaboratorsList,
  selectCollaboratorsTotal,
} from '../../../store/collaborators';

import { PageContainer } from '../../styles';
import { User } from '@styled-icons/boxicons-solid/';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Filters from './Filters';
import List from './List';
import Pagination from '../../../components/Pagination';

function CollaboratorsList() {
  const dispatch = useDispatch();
  const collaborators = useSelector(selectCollaboratorsList);
  const total = useSelector(selectCollaboratorsTotal);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    source: withDefault(StringParam, ''),
    target: withDefault(StringParam, ''),
    materia: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 30),
  });

  React.useEffect(() => {
    dispatch(fetchCollaboratorsList(query));
  }, [query]);

  function resetFilters() {
    setQuery({ search: '', source: '', target: '', materia: '', offset: 0 });
  }

  return (
    <Layout>
      <Header
        text="COLLABORATORI"
        TextIcon={User}
        buttonTooltipText="Aggiungi un nuovo collaboratore"
        buttonRedirectUri="/collaborator"
      />
      <PageContainer>
        <Filters query={query} setQuery={setQuery} resetFilters={resetFilters} />
        <List data={collaborators} />
        <Pagination
          total={total}
          limit={query.limit}
          offset={query.offset}
          onPageChange={(offset) => setQuery({ offset })}
        />
      </PageContainer>
    </Layout>
  );
}

export default CollaboratorsList;

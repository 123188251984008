import React from 'react';

export const MATERIA = {
  TECNICA: 'Tecnica',
  LEGALE_FINANZIARIA: 'Legale/finanziaria',
  COMUNICAZIONE: 'Comunicazione',
  LETTERARIA: 'Letteraria',
  INFORMATICA: 'Informatica',
};

function MateriaCollaboratoreOptions() {
  return Object.entries(MATERIA).map(([key, value], index) => (
    <option key={index} value={key}>
      {value}
    </option>
  ));
}

export default MateriaCollaboratoreOptions;

import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';

const AppLoadingContainer = styled.div`
  height: 100vh;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    animation: fade 1s ease;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

function AppLoading() {
  return (
    <AppLoadingContainer>
      <Logo />
    </AppLoadingContainer>
  );
}

export default AppLoading;

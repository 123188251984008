import React from 'react';
import { useFormikContext } from 'formik';

import { FormGroupTitle, FormRow } from '../../styles';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import RegimeIVAOptions from '../../../options/RegimeIVAOptions';
import CondizioniPagamentoOptions from '../../../options/CondizioniPagamentoOptions';
import UpperCaseEventValue from '../../../utils/UpperCaseEventValue';

function Amministrazione() {
  const { values, errors, handleChange } = useFormikContext();

  const { Amministrazione: AmministrazioneValues = {} } = values;
  const { Amministrazione: AmministrazioneErrors = {} } = errors;

  return (
    <React.Fragment>
      <FormGroupTitle>Amministrazione</FormGroupTitle>
      <FormRow>
        <Input
          label="Partita IVA"
          name="Amministrazione.PartitaIVA"
          value={AmministrazioneValues.PartitaIVA}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.PartitaIVA}
        />
        <Input
          label="Codice Fiscale"
          name="Amministrazione.CodiceFiscale"
          value={AmministrazioneValues.CodiceFiscale}
          onChange={UpperCaseEventValue(handleChange)}
          errorLabel={AmministrazioneErrors.CodiceFiscale}
        />
        <Select
          label="Regime IVA Predefinito"
          name="Amministrazione.RegimeIVA"
          value={AmministrazioneValues.RegimeIVA}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.RegimeIVA}>
          <option value=""></option>
          <RegimeIVAOptions />
        </Select>
      </FormRow>

      <FormRow>
        <Select
          label="Condizioni di Pagamento"
          name="Amministrazione.CondizioniPagamento"
          value={AmministrazioneValues.CondizioniPagamento}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.CondizioniPagamento}>
          <option value=""></option>
          <CondizioniPagamentoOptions />
        </Select>
      </FormRow>

      <FormRow>
        <Input
          label="IBAN"
          name="Amministrazione.IBAN"
          value={AmministrazioneValues.IBAN}
          onChange={UpperCaseEventValue(handleChange)}
          errorLabel={AmministrazioneErrors.IBAN}
        />
      </FormRow>

      <FormRow>
        <Input
          label="Banca"
          name="Amministrazione.Banca"
          value={AmministrazioneValues.Banca}
          onChange={handleChange}
        />
        <Input
          label="Agenzia"
          name="Amministrazione.Agenzia"
          value={AmministrazioneValues.Agenzia}
          onChange={handleChange}
        />
      </FormRow>
    </React.Fragment>
  );
}

export default Amministrazione;

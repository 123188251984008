import React from 'react';

const REGIME_IVA = [
  'Aliquota 4% tab. A parte II° nr.18',
  'Escl. Art. 7-ter',
  'Esente giusta art. 8 DPR 633/72',
  'Esente giusta art. 8-8Bis DPR 633/72 e succ mod.',
  'Esente IVA art. 14 C. 10 legge 537/1993',
  'Non imp. art. 15 DPR 633/72',
  'Non imp. IVA art. 72 DPR 633/72',
  'Non imp. IVA art. 8/1c Lett. C DPR 633/72',
  'Normale 22%',
  "Scissione dei pagamenti ai sensi dell'art. 17 ter",
];

function RegimeIVAOptions() {
  return REGIME_IVA.map((regime, index) => (
    <option key={index} value={regime}>
      {regime}
    </option>
  ));
}

export default RegimeIVAOptions;

import * as Yup from 'yup';

export const ReferenteSchema = Yup.object().shape({
  Qualifica: Yup.string().required('Campo obbligatorio'),
  Nome: Yup.string().required('Campo obbligatorio'),
  Cognome: Yup.string().required('Campo obbligatorio'),
  Sesso: Yup.string().required('Campo obbligatorio'),
  Telefono: Yup.string().required('Campo obbligatorio'),
  Cellulare: Yup.string().required('Campo obbligatorio'),
  Email: Yup.string().email('Inserisci una Email valida').required('Campo obbligatorio'),
});

export const ClientSchema = Yup.object().shape({
  Anagrafica: Yup.object()
    .shape({
      RagioneSociale: Yup.string().required('Campo obbligatorio'),
      Indirizzo: Yup.string().required('Campo obbligatorio'),
      Comune: Yup.string().required('Campo obbligatorio'),
      Provincia: Yup.string().required('Campo obbligatorio'),
      CAP: Yup.string().required('Campo obbligatorio'),
      Stato: Yup.string().required('Campo obbligatorio'),
      Telefono: Yup.string().required('Campo obbligatorio'),
      Cellulare: Yup.string().required('Campo obbligatorio'),
      Email: Yup.string().email('Inserisci una Email valida').required('Campo obbligatorio'),
    })
    .required(),
  Amministrazione: Yup.object()
    .shape({
      PartitaIVA: Yup.string().required('Campo obbligatorio'),
      CodiceFiscale: Yup.string().required('Campo obbligatorio'),
      RegimeIVA: Yup.string().required('Campo obbligatorio'),
      CondizioniPagamento: Yup.string().required('Campo obbligatorio'),
      IBAN: Yup.string().required('Campo obbligatorio'),
    })
    .required(),
  Referenti: Yup.array().of(ReferenteSchema).required(),
});

import 'react-tabs/style/react-tabs.css';
import React from 'react';
import { Formik } from 'formik';

import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchOrderById,
  selectCurrentOrder,
  saveOrder,
  resetOrder,
  createOrder,
  deleteOrder,
  fetchRegimiList,
} from '../../../store/orders';

import GoBack from '../../../components/GoBack';
import { PageContainer, Form } from '../../styles';
import { DocumentText } from '@styled-icons/heroicons-solid';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Generale from './Generale';
import Note from './Note';
import Servizi from './Servizi';
import Fatturazione from './Fatturazione';
import Attivita from './Attivita';
import Buttons from './Buttons';

import { OrderSchema } from '../schema';

function Detail() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const order = useSelector(selectCurrentOrder);
  // Effect per fetchare il cliente se id esiste

  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(fetchOrderById(id));
    }

    // dispatch(fetchRegimiList());
  }, [id]);

  // Effect per resettare il form al unmount del componente
  React.useEffect(() => () => dispatch(resetOrder()), []);

  // Effect per inserire l'id dell'ordine creato all'url
  React.useEffect(() => {
    if (id === undefined && order._id !== undefined) {
      history.replace(`/order/${order._id}`);
    }
  }, [order._id]);

  function onSaveClick(values) {
    console.log(values);
    if (id !== undefined) {
      return dispatch(saveOrder(values));
    }

    dispatch(createOrder(values));
  }

  function onDeleteConfirm() {
    if (order._id !== undefined) {
      dispatch(deleteOrder(order._id));
    }
  }

  return (
    <Layout>
      <Header text="ORDINI" TextIcon={DocumentText} />
      <Formik
        validateOnChange={true}
        validationSchema={OrderSchema}
        enableReinitialize={true}
        initialValues={order}
        onSubmit={onSaveClick}>
        <PageContainer>
          <GoBack />
          <Form>
            <Generale />
            <Note />
            <Servizi />
            <Attivita />
            {/* <Fatturazione /> */}
            <Buttons data={order} onDeleteConfirm={onDeleteConfirm} />
          </Form>
        </PageContainer>
      </Formik>
    </Layout>
  );
}

export default Detail;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LeftArrowAlt } from '@styled-icons/boxicons-regular';

const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
`;

const GoBackButton = styled(LeftArrowAlt)`
  color: #95bc00;
  width: 40px;

  cursor: pointer;
`;

const GoBackText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #95bc00;

  cursor: pointer;
`;

function GoBack() {
  const history = useHistory();

  return (
    <GoBackContainer>
      <GoBackButton onClick={() => history.goBack()} />
      <GoBackText onClick={() => history.goBack()}>INDIETRO</GoBackText>
    </GoBackContainer>
  );
}

export default GoBack;

import React, { useState, cloneElement } from 'react';
import Modal from 'react-modal';
import { useFormik, useFormikContext } from 'formik';
import styled from 'styled-components';
import { Form, FormRow } from '../../../styles';

import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import TextArea from '../../../../components/TextArea';
import { ConfirmButton, CancelButton } from '../../../../components/Buttons';

import { ReferenteSchema } from '../../schema';

Modal.setAppElement('#modal');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
};

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

function ReferenteEditModal({ referenteIndex, children }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((state) => !state);

  const childrenComponents = cloneElement(children, {
    onClick: toggleOpen,
  });

  const onCancel = () => {
    resetForm();
    toggleOpen();
  };

  const onSubmit = (referente) => {
    const Referenti = [...contextValues.Referenti];
    Referenti[referenteIndex] = referente;

    setContextValues({ ...contextValues, Referenti });
    toggleOpen();
    resetForm();
  };

  const { values: contextValues, setValues: setContextValues } = useFormikContext();
  const { values, handleChange, errors, handleSubmit, resetForm } = useFormik({
    validateOnChange: true,
    initialValues: { ...contextValues.Referenti[referenteIndex] },
    // validationSchema: ReferenteSchema,
    onSubmit,
  });

  return (
    <React.Fragment>
      {childrenComponents}
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <Input label="Titolo" name="Titolo" value={values.Titolo} onChange={handleChange} />
              <Input
                label="Qualifica"
                name="Qualifica"
                value={values.Qualifica}
                onChange={handleChange}
                errorLabel={errors.Qualifica}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Nome"
                name="Nome"
                value={values.Nome}
                onChange={handleChange}
                errorLabel={errors.Nome}
              />
              <Input
                label="Cognome"
                name="Cognome"
                value={values.Cognome}
                onChange={handleChange}
                errorLabel={errors.Cognome}
              />
              <Select label="Sesso" name="Sesso" value={values.Sesso} onChange={handleChange}>
                <option value="M">Maschio</option>
                <option value="F">Femmina</option>
              </Select>
            </FormRow>
            <FormRow>
              <Input
                label="Telefono"
                name="Telefono"
                value={values.Telefono}
                onChange={handleChange}
                errorLabel={errors.Telefono}
              />
              <Input
                label="Cellulare"
                name="Cellulare"
                value={values.Cellulare}
                onChange={handleChange}
                errorLabel={errors.Cellulare}
              />
              <Input label="Fax" name="Fax" value={values.Fax} onChange={handleChange} />
            </FormRow>
            <FormRow>
              <Input
                label="Email"
                name="Email"
                value={values.Email}
                onChange={handleChange}
                errorLabel={errors.Email}
              />
            </FormRow>
            <FormRow>
              <TextArea label="Note" name="Note" value={values.Note} onChange={handleChange} />
            </FormRow>

            <ModalButtonsContainer>
              <CancelButton type="button" onClick={onCancel}>
                Annulla
              </CancelButton>
              <ConfirmButton type="submit">Conferma</ConfirmButton>
            </ModalButtonsContainer>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default ReferenteEditModal;

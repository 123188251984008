import React from 'react';

export const UNITA = {
  PAROLE_1000: '1000 Parole',
  BATTUTE: 'Battute',
  CARATTERI: 'Caratteri',
  CARTELLE: 'Cartelle',
  FORFAIT: 'Forfait',
  GIORNATA_48: 'Giornata (8h)',
  GIORNATA_4: 'Mezza giornata (4h)',
  PAROLE: 'Parole',
  RIGHE_55: 'Righe 55 battute',
  RIGHE_60: 'Righe 60 battute',
  MINIMA: 'Tariffa minima',
};

function UnitaMisuraOptions() {
  return Object.entries(UNITA).map(([key, value], index) => (
    <option key={index} value={key}>
      {value}
    </option>
  ));
}

export default UnitaMisuraOptions;

import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { InputContainer, StyledLabel, StyledInputError } from './styles';
import { useDebounce } from 'use-debounce';

function SearchableSelect({
  label,
  name,
  value,
  children = [],
  fetchOptions,
  setFieldValue,
  errorLabel,
}) {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 400);

  useEffect(() => {
    fetchOptions(debouncedInputValue);
  }, [debouncedInputValue]);

  return (
    <InputContainer>
      <StyledLabel>{label}</StyledLabel>
      <ReactSelect
        name={name}
        options={children}
        inputValue={inputValue}
        onInputChange={setInputValue}
        value={children.find((option) => option.value === value)}
        onChange={({ value }) => setFieldValue(name, value)}
      />
      {errorLabel && <StyledInputError>{errorLabel}</StyledInputError>}
    </InputContainer>
  );
}

export default SearchableSelect;

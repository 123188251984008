import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { HeaderContainer, HeaderText, AddButton } from './styles';

function Header({ text, TextIcon, buttonTooltipText, buttonRedirectUri }) {
  const history = useHistory();

  const onAddClick = () => {
    history.push(buttonRedirectUri);

    ReactTooltip.hide();
  };

  return (
    <HeaderContainer>
      <HeaderText>
        {TextIcon && <TextIcon size={32} />} {text}
      </HeaderText>
      {buttonRedirectUri && <AddButton data-tip={buttonTooltipText} onClick={onAddClick} />}
      <ReactTooltip />
    </HeaderContainer>
  );
}

export default Header;

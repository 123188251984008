import React from 'react';
import { useFormikContext } from 'formik';

import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';

import {
  FormGroupTitle,
  ListContainer,
  ListHeader,
  ListHeaderText,
  ListBodyContainer,
  ListBodyItem,
  ListBodyItemText,
  AddContainer,
  NoDataContainer,
  StyledDeleteIcon,
  StyledEditIcon,
} from '../../../styles';

import { AddButton } from '../../../../components/styles';
import ModalConfirm from '../../../../components/ModalConfirm';

import AttivitaAddModal from './AttivitaAddModal';
import AttivitaEditModal from './AttivitaEditModal';

function Attivita() {
  const { values, setValues } = useFormikContext();

  const { Attivita: AttivitaValues = {} } = values;

  const onAttivitaDelete = (attivitaIndex) => {
    setValues({
      ...values,
      Attivita: AttivitaValues.filter((_, index) => index !== attivitaIndex),
    });
  };

  return (
    <React.Fragment>
      <FormGroupTitle>
        Attività
        <AttivitaAddModal>
          <AddButton data-tip="Aggiungi un'attività" style={{ color: '#95bc00' }} />
        </AttivitaAddModal>
      </FormGroupTitle>
      <AddContainer>
        <ListHeader>
          <ListHeaderText>Data inizio</ListHeaderText>
          <ListHeaderText>Data prevista</ListHeaderText>
          <ListHeaderText>Durata stimata (GG)</ListHeaderText>
          <ListHeaderText>Lingua Source</ListHeaderText>
          <ListHeaderText>Lingua Target</ListHeaderText>
          <ListHeaderText>Collaboratore</ListHeaderText>
          <ListHeaderText></ListHeaderText>
        </ListHeader>

        <ListContainer>
          {AttivitaValues.length > 0 ? (
            <ListBodyContainer noScroll>
              {AttivitaValues.map((attivita, index) => {
                const {
                  DataInizio,
                  DataPrevista,
                  DurataStimata,
                  LinguaSource,
                  LinguaTarget,
                  Collaboratore,
                } = attivita;
                const { RagioneSociale } = Collaboratore?.Anagrafica;

                return (
                  <ListBodyItem key={index}>
                    <ListBodyItemText>
                      {format(new Date(DataInizio), 'dd/MM/yyyy')}
                    </ListBodyItemText>
                    <ListBodyItemText>
                      {format(new Date(DataPrevista), 'dd/MM/yyyy')}
                    </ListBodyItemText>
                    <ListBodyItemText>{DurataStimata}</ListBodyItemText>
                    <ListBodyItemText>{LinguaSource}</ListBodyItemText>
                    <ListBodyItemText>{LinguaTarget}</ListBodyItemText>
                    <ListBodyItemText>{RagioneSociale}</ListBodyItemText>
                    <ListBodyItemText>
                      <AttivitaEditModal attivitaIndex={index}>
                        <StyledEditIcon />
                      </AttivitaEditModal>
                      <ModalConfirm
                        body="Sei sicuro di voler eliminare l'attività?"
                        onConfirm={() => onAttivitaDelete(index)}>
                        <StyledDeleteIcon />
                      </ModalConfirm>
                    </ListBodyItemText>
                  </ListBodyItem>
                );
              })}
            </ListBodyContainer>
          ) : (
            <NoDataContainer>
              <p>Nessuna attività</p>
            </NoDataContainer>
          )}
        </ListContainer>

        <ReactTooltip />
      </AddContainer>
    </React.Fragment>
  );
}

export default Attivita;

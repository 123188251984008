import 'react-tabs/style/react-tabs.css';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  fetchClientById,
  selectCurrentClient,
  createClient,
  saveClient,
  resetClient,
  deleteClient,
} from '../../../store/clients';

import GoBack from '../../../components/GoBack';
import { Group } from '@styled-icons/boxicons-solid/';
import { PageContainer, Form } from '../../styles';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Anagrafica from './Anagrafica';
import Amministrazione from './Amministrazione';
import Referenti from './Referenti';
import Buttons from './Buttons';

import { ClientSchema } from '../schema';

function Detail() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const client = useSelector(selectCurrentClient);

  // Effect per fetchare il cliente se id esiste
  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(fetchClientById(id));
    }
  }, [id]);

  // Effect per resettare il form al unmount del componente
  React.useEffect(() => () => dispatch(resetClient()), []);

  // Effect per inserire l'id del cliente creato all'url
  React.useEffect(() => {
    if (id === undefined && client._id !== undefined) {
      history.replace(`/client/${client._id}`);
    }
  }, [client._id]);

  function onSaveClick(values) {
    if (id !== undefined) {
      return dispatch(saveClient(values));
    }

    dispatch(createClient(values));
  }

  function onDeleteConfirm() {
    if (client._id !== undefined) {
      dispatch(deleteClient(client._id));
    }
  }

  return (
    <Layout>
      <Header text="CLIENTI" TextIcon={Group} />
      <Formik
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={client}
        onSubmit={onSaveClick}
        // validationSchema={ClientSchema}
      >
        <PageContainer>
          <GoBack />
          <Form>
            <Anagrafica />
            <Amministrazione />
            <Referenti />
            <Buttons data={client} onDeleteConfirm={onDeleteConfirm} />
          </Form>
        </PageContainer>
      </Formik>
    </Layout>
  );
}

export default Detail;

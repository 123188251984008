import React from 'react';
import { useFormikContext } from 'formik';

import { FormGroupTitle, FormRow } from '../../styles';

import Input from '../../../components/Input';
import ComuneAutoComplete from '../../../components/ComuneAutoComplete';
import Select from '../../../components/Select';
import NationOptions from '../../../options/NationOptions';
import CondizioniPagamentoOptions from '../../../options/CondizioniPagamentoOptions';
import UpperCaseEventValue from '../../../utils/UpperCaseEventValue';

import ValutaOptions from '../../../options/ValutaOptions';

function Amministrazione() {
  const { values, errors, handleChange, setValues } = useFormikContext();

  function setBirthData(data) {
    data.ComuneNascita = data.Comune;
    data.ProvinciaNascita = data.Provincia;
    data.StatoNascita = data.Stato;

    delete data.Stato;
    delete data.Provincia;
    delete data.Comune;
    delete data.CAP;

    setValues({
      ...values,
      Amministrazione: { ...AmministrazioneValues, ...data },
    });
  }

  const { Amministrazione: AmministrazioneValues = {} } = values;
  const { Amministrazione: AmministrazioneErrors = {} } = errors;

  return (
    <React.Fragment>
      <FormGroupTitle>Amministrazione</FormGroupTitle>
      <FormRow>
        <Input
          label="Partita IVA"
          name="Amministrazione.PartitaIVA"
          value={AmministrazioneValues.PartitaIVA}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.PartitaIVA}
        />
        <Input
          label="Codice Fiscale"
          name="Amministrazione.CodiceFiscale"
          value={AmministrazioneValues.CodiceFiscale}
          onChange={UpperCaseEventValue(handleChange)}
          errorLabel={AmministrazioneErrors.CodiceFiscale}
        />
        <Input
          label="Data di Nascita"
          type="date"
          name="Amministrazione.DataNascita"
          value={AmministrazioneValues.DataNascita}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.DataNascita}
        />
      </FormRow>

      <FormRow>
        <ComuneAutoComplete
          label="Comune di Nascita"
          name="Amministrazione.ComuneNascita"
          value={AmministrazioneValues.ComuneNascita}
          onChange={handleChange}
          onFetchComplete={setBirthData}
          errorLabel={AmministrazioneErrors.ComuneNascita}
        />
        <Input
          label="Provincia di Nascita"
          name="Amministrazione.ProvinciaNascita"
          value={AmministrazioneValues.ProvinciaNascita}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.ProvinciaNascita}
        />
        <Select
          label="Stato di Nascita"
          name="Amministrazione.StatoNascita"
          value={AmministrazioneValues.StatoNascita}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.StatoNascita}>
          <option value=""></option>
          <NationOptions />
        </Select>
      </FormRow>

      <FormRow>
        <Select
          label="Valuta"
          name="Amministrazione.Valuta"
          value={AmministrazioneValues.Valuta}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.Valuta}>
          <option value=""></option>
          <ValutaOptions />
        </Select>
        <Select
          label="Condizioni di Pagamento"
          name="Amministrazione.CondizioniPagamento"
          value={AmministrazioneValues.CondizioniPagamento}
          onChange={handleChange}
          errorLabel={AmministrazioneErrors.CondizioniPagamento}>
          <option value=""></option>
          <CondizioniPagamentoOptions />
        </Select>
      </FormRow>

      <FormRow>
        <Input
          label="IBAN"
          name="Amministrazione.IBAN"
          value={AmministrazioneValues.IBAN}
          onChange={UpperCaseEventValue(handleChange)}
          errorLabel={AmministrazioneErrors.IBAN}
        />
      </FormRow>

      <FormRow>
        <Input
          label="Banca"
          name="Amministrazione.Banca"
          value={AmministrazioneValues.Banca}
          onChange={handleChange}
        />
        <Input
          label="Agenzia"
          name="Amministrazione.Agenzia"
          value={AmministrazioneValues.Agenzia}
          onChange={handleChange}
        />
      </FormRow>
    </React.Fragment>
  );
}

export default Amministrazione;

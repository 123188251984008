import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Provider as ReduxStateProvider } from 'react-redux';
import store from './store';

import history from './routes/history';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

import AuthProvider from './context/AuthProvider';
import AlertProvider from './context/AlertProvider';

import AlertVisualizer from './components/AlertVisualizer';
import Spinner from './components/Spinner';

// Auth
import Login from './pages/Auth';

// Dashboard
import Dashboard from './pages/Dashboard';

// Clienti
import ClientsList from './pages/Clients/List';
import ClientsDetail from './pages/Clients/Detail';

// Collaboratori
import CollaboratorsList from './pages/Collaborators/List';
import CollaboratorsDetail from './pages/Collaborators/Detail';

// Commesse
import OrdersList from './pages/Orders/List';
import OrdersDetail from './pages/Orders/Detail';

// Fatture
import InvoicesList from './pages/Invoices/List';

function App() {
  return (
    <ReduxStateProvider store={store}>
      <AuthProvider>
        <AlertProvider>
          <AlertVisualizer>
            <Spinner />
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  <PublicRoute exact path="/login" component={Login} />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/clients" component={ClientsList} />
                  <PrivateRoute exact path="/client" component={ClientsDetail} />
                  <PrivateRoute exact path="/client/:id" component={ClientsDetail} />
                  <PrivateRoute exact path="/collaborators" component={CollaboratorsList} />
                  <PrivateRoute exact path="/collaborator" component={CollaboratorsDetail} />
                  <PrivateRoute exact path="/collaborator/:id" component={CollaboratorsDetail} />
                  <PrivateRoute exact path="/orders" component={OrdersList} />
                  <PrivateRoute exact path="/order" component={OrdersDetail} />
                  <PrivateRoute exact path="/order/:id" component={OrdersDetail} />
                  <PrivateRoute exact path="/invoices" component={InvoicesList} />
                  <Redirect to="/dashboard" />
                </Switch>
              </QueryParamProvider>
            </Router>
          </AlertVisualizer>
        </AlertProvider>
      </AuthProvider>
    </ReduxStateProvider>
  );
}

export default App;

import React, { useEffect } from 'react';
import { StringParam, NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { PageContainer } from '../../styles';
import { FileInvoiceDollar } from '@styled-icons/fa-solid';

import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import Filters from './Filters';
import List from './List';
import Pagination from '../../../components/Pagination';

import { startOfMonth } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchInvoicesList,
  selectInvoicesList,
  selectInvoicesTotal,
} from '../../../store/invoices';

function InvoicesList() {
  const dispatch = useDispatch();
  const invoices = useSelector(selectInvoicesList);
  const total = useSelector(selectInvoicesTotal);

  const [query, setQuery] = useQueryParams({
    startDate: withDefault(StringParam, startOfMonth(new Date()).toISOString().substring(0, 10)),
    endDate: withDefault(StringParam, new Date().toISOString().substring(0, 10)),
    search: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, 25),
  });

  useEffect(() => {
    const { search, startDate, endDate, offset } = query;

    dispatch(fetchInvoicesList({ search, startDate, endDate, offset }));
  }, [query]);

  return (
    <Layout>
      <Header
        text="FATTURE"
        TextIcon={FileInvoiceDollar}
        buttonTooltipText="Nuova fattura"
        buttonRedirectUri="/invoices"
      />
      <PageContainer>
        <Filters query={query} setQuery={setQuery} />
        <List data={invoices} />
        <Pagination
          total={total}
          limit={query.limit}
          offset={query.offset}
          onPageChange={(offset) => setQuery({ offset })}
        />
      </PageContainer>
    </Layout>
  );
}

export default InvoicesList;

import React from 'react';
import ReactPaginate from 'react-paginate';

import styled from 'styled-components';

import { PaginationContainer } from './styles';

import { LeftArrowSquare, RightArrowSquare } from '@styled-icons/boxicons-solid';

const ArrowLeftIcon = styled(LeftArrowSquare)`
  width: 40px;
`;
const ArrowRightIcon = styled(RightArrowSquare)`
  width: 40px;
`;

function Pagination({ total = 0, limit = 20, offset = 0, onPageChange }) {
  const pageCount = Math.max(total / limit, 1);

  return (
    <PaginationContainer>
      <ReactPaginate
        onPageChange={({ selected }) => onPageChange(selected * limit)}
        containerClassName="pagination"
        forcePage={offset / limit}
        pageCount={pageCount}
        pageRangeDisplayed={4}
        marginPagesDisplayed={2}
        previousLabel={<ArrowLeftIcon />}
        nextLabel={<ArrowRightIcon />}
      />
    </PaginationContainer>
  );
}

export default Pagination;

import React from 'react';
import { InputContainer, StyledLabel, StyledSelect, StyledInputError } from './styles';

function Select({ label, name, value, children, errorLabel, ...props }) {
  return (
    <InputContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect name={name} value={value} {...props}>
        {children}
      </StyledSelect>
      {errorLabel && <StyledInputError>{errorLabel}</StyledInputError>}
    </InputContainer>
  );
}

export default Select;

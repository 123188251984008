import React from 'react';
import { useFormikContext } from 'formik';

import { FormGroupTitle, FormRow } from '../../styles';
import TextArea from '../../../components/TextArea';

function Note() {
  const { values, errors, handleChange } = useFormikContext();

  const { Note: NoteValues = {} } = values;
  const { Note: NoteErrors = {} } = errors;

  return (
    <React.Fragment>
      <FormGroupTitle>Note</FormGroupTitle>
      <FormRow>
        <TextArea
          label="Generali"
          name="Note.Generali"
          value={NoteValues.Generali}
          onChange={handleChange}
          errorLabel={NoteErrors.Generali}
        />
        <TextArea
          label="Collaboratore"
          name="Note.Collaboratore"
          value={NoteValues.Collaboratore}
          onChange={handleChange}
          errorLabel={NoteErrors.Collaboratore}
        />
      </FormRow>
    </React.Fragment>
  );
}

export default Note;

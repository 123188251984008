import React from 'react';
import nations from '../utils/nations';

function NationOptions() {
  return nations.map(({ code, name }, index) => (
    <option key={index} value={code}>
      {name}
    </option>
  ));
}

export default NationOptions;

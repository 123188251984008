import React from 'react';
import { ListHeader, ListHeaderText, ListContainer, ListBodyContainer } from '../../styles';

import ListItem from './ListItem';

function List({ data = [] }) {
  return (
    <ListContainer>
      <ListHeader>
        <ListHeaderText>Numero</ListHeaderText>
        <ListHeaderText>Data</ListHeaderText>
        <ListHeaderText col={5}>Nome e cognome / Ragione sociale</ListHeaderText>
        <ListHeaderText>Valuta</ListHeaderText>
        <ListHeaderText>Importo netto</ListHeaderText>
        <ListHeaderText>Importo totale</ListHeaderText>
      </ListHeader>

      <ListBodyContainer>
        {data.map((item, index) => (
          <ListItem key={index} item={item} />
        ))}
      </ListBodyContainer>
    </ListContainer>
  );
}

export default List;

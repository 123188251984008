import React, { useEffect } from 'react';
import get from 'lodash.get';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { FormGroupTitle, FormRow } from '../../styles';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import SearchableSelect from '../../../components/SearchableSelect';

import MateriaCollaboratoreOptions from '../../../options/MateriaCollaboratoreOptions';

import { fetchClientsList, selectClientsList } from '../../../store/clients';

function Generale() {
  const dispatch = useDispatch();
  const Clienti = useSelector(selectClientsList);

  const { values, errors, handleChange, setFieldValue } = useFormikContext();

  const { Generale: GeneraleValues = {} } = values;
  const { Generale: GeneraleErrors = {} } = errors;

  const fetchClients = (search) => {
    dispatch(fetchClientsList({ search }));
  };

  const formatClientsOptions = () => {
    return Clienti.map((cliente) => ({
      value: cliente._id,
      label: get(cliente, 'Anagrafica.RagioneSociale'),
    }));
  };

  useEffect(() => {
    const client = Clienti.find((cliente) => cliente._id === GeneraleValues.Cliente?._id);

    if (client) {
      setFieldValue('Generale.Cliente', client);
    }
  }, [GeneraleValues.Cliente?._id]);

  return (
    <React.Fragment>
      <FormGroupTitle>Generale</FormGroupTitle>
      <FormRow>
        <SearchableSelect
          label="Cliente"
          name="Generale.Cliente"
          value={GeneraleValues.Cliente?._id}
          placeholder="Ricerca cliente..."
          setFieldValue={(field, value) => setFieldValue(`${field}._id`, value)}
          errorLabel={GeneraleErrors.Cliente?._id}
          fetchOptions={fetchClients}>
          {formatClientsOptions()}
        </SearchableSelect>

        <Input
          label="Referente"
          name="Generale.Referente"
          value={GeneraleValues.Referente}
          onChange={handleChange}
          errorLabel={GeneraleErrors.Referente}
        />
      </FormRow>

      <FormRow>
        <Input
          label="Codice"
          name="Generale.Codice"
          value={GeneraleValues.Codice}
          onChange={handleChange}
          errorLabel={GeneraleErrors.Codice}
        />

        <Input
          label="Data"
          type="date"
          name="Generale.Data"
          value={GeneraleValues.Data}
          onChange={handleChange}
          errorLabel={GeneraleErrors.Data}
        />

        <Input
          label="Descrizione"
          name="Generale.Descrizione"
          value={GeneraleValues.Descrizione}
          onChange={handleChange}
          errorLabel={GeneraleErrors.Descrizione}
        />
      </FormRow>

      <FormRow>
        <Input
          label="Responsabile"
          name="Generale.Responsabile"
          value={GeneraleValues.Responsabile}
          onChange={handleChange}
          errorLabel={GeneraleErrors.Responsabile}
        />

        <Select
          label="Materia"
          name="Generale.Materia"
          value={GeneraleValues.Materia}
          onChange={handleChange}
          errorLabel={GeneraleErrors.Materia}>
          <option value=""></option>
          <MateriaCollaboratoreOptions />
        </Select>

        <Input
          label="Data Richiesta"
          type="date"
          name="Generale.DataRichiesta"
          value={GeneraleValues.DataRichiesta}
          onChange={handleChange}
          errorLabel={GeneraleErrors.DataRichiesta}
        />

        <Input
          label="Data Consegna"
          type="date"
          name="Generale.DataConsegna"
          value={GeneraleValues.DataConsegna}
          onChange={handleChange}
          errorLabel={GeneraleErrors.DataConsegna}
        />
      </FormRow>
    </React.Fragment>
  );
}

export default Generale;

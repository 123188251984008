import React, { useState, cloneElement } from 'react';
import Modal from 'react-modal';
import { useFormik, useFormikContext } from 'formik';
import styled from 'styled-components';
import { Form, FormRow } from '../../../styles';

import Input from '../../../../components/Input';
import Select from '../../../../components/Select';

import { SpecialitaSchema } from '../../schema';
import { ConfirmButton, CancelButton } from '../../../../components/Buttons';

import AttivitaCollaboratoreOptions from '../../../../options/AttivitaCollaboratoreOptions';
import MateriaCollaboratoreOptions from '../../../../options/MateriaCollaboratoreOptions';
import LingueOptions from '../../../../options/LingueOptions';
import UnitaMisuraOptions from '../../../../options/UnitaMisuraOptions';
import ValutaOptions from '../../../../options/ValutaOptions';

Modal.setAppElement('#modal');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
};

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

function SpecialitaEditModal({ specialitaIndex, children }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((state) => !state);

  const childrenComponents = cloneElement(children, {
    onClick: toggleOpen,
  });

  const onCancel = () => {
    resetForm();
    toggleOpen();
  };

  const onSubmit = (specialita) => {
    const Specialita = [...contextValues.Specialita];
    Specialita[specialitaIndex] = specialita;

    setContextValues({ ...contextValues, Specialita });
    toggleOpen();
    resetForm();
  };

  const { values: contextValues, setValues: setContextValues } = useFormikContext();
  const { values, handleChange, errors, handleSubmit, resetForm } = useFormik({
    validateOnChange: true,
    initialValues: { ...contextValues.Specialita[specialitaIndex] },
    // validationSchema: SpecialitaSchema,
    onSubmit,
  });

  return (
    <React.Fragment>
      {childrenComponents}
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormRow>
              <Select
                label="Attività"
                name="Attivita"
                value={values.Attivita}
                onChange={handleChange}
                errorLabel={errors.Attivita}>
                <option value=""></option>
                <AttivitaCollaboratoreOptions />
              </Select>
              <Select
                label="Materia"
                name="Materia"
                value={values.Materia}
                onChange={handleChange}
                errorLabel={errors.Materia}>
                <option value=""></option>
                <MateriaCollaboratoreOptions />
              </Select>
            </FormRow>

            <FormRow>
              <Select
                label="Lingua Source"
                name="LinguaSource"
                value={values.LinguaSource}
                onChange={handleChange}
                errorLabel={errors.LinguaSource}>
                <option value=""></option>
                <LingueOptions />
              </Select>
              <Select
                label="Lingua Target"
                name="LinguaTarget"
                value={values.LinguaTarget}
                onChange={handleChange}
                errorLabel={errors.LinguaTarget}>
                <option value=""></option>
                <LingueOptions />
              </Select>
              <Select
                label="Unità di Misura"
                name="UnitaMisura"
                value={values.UnitaMisura}
                onChange={handleChange}
                errorLabel={errors.UnitaMisura}>
                <option value=""></option>
                <UnitaMisuraOptions />
              </Select>
            </FormRow>

            <FormRow>
              <Input
                label="Prezzo"
                name="Prezzo"
                value={values.Prezzo}
                onChange={handleChange}
                errorLabel={errors.Prezzo}
              />
            </FormRow>

            <FormRow>
              <Input
                label="Valutazione"
                name="Valutazione"
                value={values.Valutazione}
                onChange={handleChange}
              />
            </FormRow>

            <ModalButtonsContainer>
              <CancelButton type="button" onClick={onCancel}>
                Annulla
              </CancelButton>
              <ConfirmButton type="submit">Conferma</ConfirmButton>
            </ModalButtonsContainer>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default SpecialitaEditModal;

import { createSlice } from '@reduxjs/toolkit';
import { fetchData } from './api';

const INITIAL_STATE = {
  list: [],
  total: 0,
  current: {
    Anagrafica: {
      RagioneSociale: '',
      Indirizzo: '',
      Comune: '',
      CAP: '',
      Provincia: '',
      Stato: '',
      Telefono: '',
      Cellulare: '',
      Fax: '',
      Email: '',
      Web: '',
      Note: '',
    },
    Amministrazione: {
      PartitaIVA: '',
      CodiceFiscale: '',
      RegimeIVA: '',
      CondizioniPagamento: '',
      Banca: '',
      Agenzia: '',
      IBAN: '',
    },
    Referenti: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'clients',
  initialState: INITIAL_STATE,
  reducers: {
    fetchedClientsList: (state, { payload }) => {
      state.list = payload.clients;
      state.total = payload.total;
    },
    fetchedClient: (state, { payload }) => {
      state.current = payload;
    },
    savedClient: (state, { payload }) => {
      state.current = payload;
    },
    createdClient: (state, { payload }) => {
      state.current = payload;
    },
    resetClient: (state) => {
      state.current = INITIAL_STATE.current;
    },
  },
});

export const {
  fetchedClientsList,
  fetchedClient,
  savedClient,
  createdClient,
  resetClient,
} = actions;
export default reducer;

// Actions
export const fetchClientsList = (params) => {
  return fetchData({
    url: '/clients',
    params,
    onSuccess: actions.fetchedClientsList.type,
  });
};

export const fetchClientById = (id) => {
  return fetchData({
    url: `/clients/${id}`,
    onSuccess: actions.fetchedClient.type,
  });
};

export const saveClient = (client) => {
  return fetchData({
    url: `/clients/${client._id}`,
    method: 'PUT',
    data: client,
    onSuccess: actions.savedClient.type,
    onSuccessAlertMessage: {
      message: 'Salvataggio effettuato con successo!',
      type: 'success',
    },
  });
};

export const createClient = (client) => {
  return fetchData({
    url: `/clients`,
    method: 'POST',
    data: client,
    onSuccess: actions.createdClient.type,
    onSuccessAlertMessage: {
      message: 'Creazione effettuata con successo!',
      type: 'success',
    },
  });
};

export const deleteClient = (_id) => {
  return fetchData({
    url: `/clients/${_id}`,
    method: 'DELETE',
    redirectUri: `/clients`,
    onSuccess: actions.resetClient.type,
    onSuccessAlertMessage: {
      message: 'Eliminazione effettuata con successo!',
      type: 'success',
    },
  });
};

// Selectors
export const selectClientsList = ({ clients }) => {
  return clients.list;
};

export const selectClientsTotal = ({ clients }) => {
  return clients.total;
};

export const selectCurrentClient = ({ clients }) => {
  return clients.current;
};
